import React from 'react';
import BlockUi from 'react-block-ui';
import { Card, CardBody, CardHeader, PopoverBody, UncontrolledPopover, Badge } from 'reactstrap';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import 'react-block-ui/style.css';

export const StyledCard = ({ block, children, icon, title, help, error, badge }) => (

    <Card className="shadow border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardHeader className="bg-white border-bottom-host">
                {icon ? <i className={icon + ' mr-1'}></i> : ''}

                {title ? title : ''}

                {badge ?
                    <Badge style={{ marginLeft: "15px" }} color="success"> {badge} </Badge>
                : ''}

                {help ? <div className="float-right">
                    <span id="mainpagehelp" className="btn-sm btn-link" onClick={() => true} >
                        <i class="text-secondary fas fa-question-circle mt-2 "></i>
                    </span>
                    <UncontrolledPopover data-trigger="focus" placement="left" target="mainpagehelp">
                        <PopoverBody>
                            {help}
                        </PopoverBody>
                    </UncontrolledPopover>

                </div> : ''}

            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>

    </Card>

);

export const BlankCard = ({ block, children, icon, title, help, error, badge }) => (

    <Card className=" border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>

    </Card>

);

export const ActiveInactiveStatusBadge = ({ status }) => (
    <Badge color={status ? 'primary' : 'secondary'} className={(status ? 'p-1 px-2 shadow  pr-3' : ' p-1 px-2 shadow ')}>{status ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.inactive" />}</Badge>
);

export const CommonHelper = ({ help, id, placement = 'left' }) => {
    return (
        <div className="float-right">
            <span id={id} className="btn-sm btn-link" onClick={() => true} style={{ fontSize: 'large' }}>
                <i className="text-secondary fas fa-question-circle mt-2 "></i>
            </span>
            <UncontrolledPopover data-trigger="focus" placement={placement} target={id}>
                <PopoverBody>
                    <div className="mb-1">
                        {help}
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    )
};