import React from "react";
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IntlContext } from "../../Utils/IntlProviderWrapper";



const LanguageSwitch = () => {
    const { switchToLangPT } = React.useContext(IntlContext);
    const { switchToLangEN } = React.useContext(IntlContext);
    const { locale } = React.useContext(IntlContext);
    const cssclasses = (locale === 'pt' ? 'flag-icon flag-icon-pt pr-2' : 'flag-icon flag-icon-us mr-2');
    

    return (
       
        <div >

            <UncontrolledDropdown direction="left" >
                <DropdownToggle tag="a" className="dropdown-item" caret>
                    <i className={cssclasses} /> {locale} 
          </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Change Language</DropdownItem>
                    <DropdownItem onClick={switchToLangPT} active={locale === 'pt' ? true : false} ><span className="flag-icon flag-icon-pt"  />PT</DropdownItem>
                    <DropdownItem onClick={switchToLangEN} active={locale === 'en' ? true : false}><span className="flag-icon flag-icon-us" />EN</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>

            
        </div>



             
           
      
    );
};


export default LanguageSwitch;