import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { NotificationContainer } from "react-notifications";
import TicketSearch from './components/Tickets/TicketSearch';
import { TicketDetail } from './components/Tickets/TicketDetail';
import { ClientFile } from './components/ClientFile/ClientFile';
import { Profile } from './components/Profile/Profile';
import ManageUser from './components/ManageUsers/ManageUser';
import GoogleAnalytics from './components/Base/Common/GoogleAnalytics';

import "react-notifications/lib/notifications.css";
import 'react-dates/lib/css/_datepicker.css';
import './css/react_dates_overrides.css';
import 'react-notifications/lib/notifications.css';
import './custom.css'

import Authorization from './components/Base/Authorization';

class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div>
              
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/TicketSearch' component={TicketSearch} />
                        <Route path='/TicketDetail/:id?' component={TicketDetail} />
                        <Route path='/ClientFile' component={ClientFile} />
                        <Route path='/Profile' component={Profile} />

                        <Authorization
                            perform="manageUser:view"
                            yes={() => (
                                <Route path='/ManageUser' component={ManageUser} />
                            )}
                            no={() => <div></div>}
                        />

                    <NotificationContainer />
                    <GoogleAnalytics />
                    </Layout>
              
            </div>
        );
    }
}

export default App
