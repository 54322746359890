import React from 'react';
import { withRouter } from 'react-router-dom';
import { authContext } from '../../../Utils/AdalConfig';

class GoogleAnalytics extends React.Component {
    componentWillUpdate({ location, history }) {
        const gtag = window.gtag;
        if (location.pathname === this.props.location.pathname) {
            return;
        }

        if (history.action === 'PUSH' &&
            typeof (gtag) === 'function') {
            gtag('config', 'G-3QQYQNRZCW', { 'user_id': authContext.getCachedUser() && authContext.getCachedUser().profile && authContext.getCachedUser().profile.oid });
            gtag('send', 'pageview',
                {
                    'page_title': location.pathname,
                    'page_location': window.location.href,
                    'page_path': location.pathname
                });
        }
    }

    render() {
        return null;
    }
}

export default withRouter(GoogleAnalytics);