import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { Editor } from "react-draft-wysiwyg";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export default function EditorText(props) {
    const defaultToolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true }
    };
    const toolbar = props.customToolbar ? props.customToolbar : defaultToolbar;

    return (
        <BlockUi tag="div" blocking={false}>
            <Editor
                editorState={props.editorState}
                onEditorStateChange={props.onEditorStateChange}
                toolbar={toolbar}
                placeholder={<FormattedMessage id="Editor.Placeholder" />}
                editorStyle={props.editorStyle || {}}
            />
        </BlockUi>
    )
};
