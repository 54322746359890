import React, { Component } from 'react';
import { Row, Col, InputGroup, Input, Button, Form, Label, Collapse, Badge } from 'reactstrap'; 
import { handleNotification } from "../Base/Common/Notification";
import CustomSelect from '../Base/Common/CustomSelect';
import { FormattedMessage } from 'react-intl';
import "../../css//react_dates_overrides.css";
import { getAPI, postAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { getTicketStatus } from '../Base/Common/ReferenceDataFunctions';
import Authorization from '../Base/Authorization';

export class StaticFields extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            status: '',
            category1: '',
            category2: '',
            category3: '',
            cat1Options: [],
            cat2Options: [],
            cat3Options: [],
            description: this.props.ticketData.Description,
            type: this.props.ticketData.Type,
            allReadOnly: false,
            collapse: this.props.ticketData.Type === 1 ? true : false,
            ticketClosed: this.props.ticketClosed
        }
    }

    componentDidMount() {
        this.getCategories();
        this.fillData('status', getTicketStatus().filter(el => el.value !== '-1'), 'Status');   
        this.verifyType(this.props.ticketData.Type);
    };

    verifyType = (type) => {
        if (type.toString() === '2') {
            this.setState({
                allReadOnly: true
            })
        }
        else {
            this.setState({
                allReadOnly: false
            })
        }
    }

    getCategories = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description
                    };

                    options.push(object);
                })

                this.setState({ cat1Options: options });
                this.fillData('category1', this.state.cat1Options, 'CategoryLvl1');
            }
        }, "/api/HelpDesk/Categories/V1");

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        lvl: category.CatLvl1Id
                    };

                    options.push(object);
                })

                this.setState({ cat2Options: options });
                this.fillData('category2', this.state.cat2Options, 'CategoryLvl2');
            }
        }, "/api/HelpDesk/Categories/V2");

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        lvl: category.CatLvl2Id
                    };

                    options.push(object);
                })

                this.setState({ cat3Options: options });
                this.fillData('category3', this.state.cat3Options, 'CategoryLvl3');                
            }
        }, "/api/HelpDesk/Categories/V3");

        this.setState({ block: false });
    };

    fillData = (name, options, prop) => {
        var state = null;

        if (name === 'status') {
            const status = options.find(status => status.value.includes(this.props.ticketData[prop]));
            state = status ? status : null;
        }
        else {
            options.map((option) => {
                if (this.props.ticketData[prop] === option.value) {
                    state = option;
                }
            });
        }

        this.setState({
            [name]: state
        })
    };
    
    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    };

    handleChangeSelect = (name, selectedOption) => {
        if (name === "category1") {
            this.setState({ category2: '', category3: '' })
        }
        if (name === "category2") {
            this.setState({ category3: '' })
        }

        this.setState({
            [name]: selectedOption
        })
    };

    handleStatus = (name, selectedOption) => {
        if (selectedOption && selectedOption.value === '5') {
            this.setState({ ticketClosed: this.props.ticketClosed })
        }
        else {
            this.setState({ ticketClosed: false })
        }

        this.setState({
            [name]: selectedOption
        })
    };

    saveData = (event) => {
        event.preventDefault();
        this.setState({ block: true, type: 2 });

        var body = { ...this.props.ticketData }

        body.ID = this.props.ticketId;
        body.Description = this.state.description;
        body.CategoryLvl1 = this.state.category1 && this.state.category1.value;
        body.CategoryLvl2 = this.state.category2 && this.state.category2.value;
        body.CategoryLvl3 = this.state.category3 && this.state.category3.value;
        body.Status = this.state.status && this.state.status.value;
        body.Type = 2;

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.success === false)
                    handleNotification(data, <FormattedMessage id="NewTicket.ErrorSavingTheTicket" />, <FormattedMessage id="Generic.Error" />);
                else {
                    handleNotification(data, <FormattedMessage id="NewTicket.TicketSavedSuccessfully" />, <FormattedMessage id="Generic.Success" />);
                    this.verifyType(this.state.type);
                    this.props.getTicketData();
                }
                this.setState({ block: false });
            }
        }, "/api/HelpDesk/ticket", body);
    };

    handleTicket = (event, closeStatus) => {
        event.preventDefault();
        this.setState({ block: true });

        var body = { ...this.props.ticketData }

        body.ID = this.props.ticketId;
        body.Status = closeStatus === 5 ? 5 : this.state.type === 1 ? 0 : 6;

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.success === false)
                    handleNotification(data, <FormattedMessage id="NewTicket.ErrorSavingTheTicket" />, <FormattedMessage id="Generic.Error" />);
                else {
                    handleNotification(data, <FormattedMessage id={closeStatus === 5 ? "StaticFields.TicketClosed" : "StaticFields.TicketReopened"} />, <FormattedMessage id="Generic.Success" />);
                    this.verifyType(this.state.type);
                    this.props.getTicketData();
                    this.setState({
                        ticketClosed: (closeStatus === 5 ? true : false),
                        status: getTicketStatus().find(status => status.value.includes(body.Status))
                    })
                }
                this.setState({ block: false });
            }
        }, "/api/HelpDesk/ticket", body);
    }

    toggleCollapse = () => {
        this.setState(prevState => ({
            collapse: !prevState.collapse
        }));
    }


    render() {
        const statusOptions = [
            {
                value: '0',
                label: <FormattedMessage id="ReferenceData.Opened" />,
            },
            {
                value: '5',
                label: <FormattedMessage id="ReferenceData.Closed" />
            }
        ]

        return (
            <BlockUi tag="div" blocking={this.state.block}>

                <Row className="border-bottom mb-1">
                    <Col className="col-12" >
                        <Label className="mr-5"> <b> {<FormattedMessage id="StaticFields.TicketNumber" />} </b> {this.props.ticketData.Nr}</Label>  
                        <Label className="mr-5"> <b> {<FormattedMessage id="StaticFields.Title" />} </b> {this.state.description} </Label>
                        <Label className="mr-5"> <b> {<FormattedMessage id="StaticFields.CreatedOn" />} </b> {moment(this.props.ticketData.ExternalCreatedOn).format("DD-MM-YYYY HH:mm")} </Label>
                        {this.props.ticketData.CreatedOn && this.state.type === 2 ? <Label className="mr-5"> <b> {<FormattedMessage id="StaticFields.SentOn" />} </b> {moment(this.props.ticketData.CreatedOn).format("DD-MM-YYYY HH:mm")} </Label> : ''}
                        <Label> <b> Hotel: </b> {this.props.ticketData.ProfileName} </Label>

                        <Button color="primary" className="float-right btn-sm btn-host" onClick={this.toggleCollapse} style={{ marginBottom: '1rem' }} size="sm">
                            <i className={this.state.collapse ? "fas fa-chevron-up" :"fas fa-chevron-down"} ></i>
                        </Button>
                    </Col>
                </Row>
                
                <Collapse isOpen={this.state.collapse}>                      
                    {this.state.cat1Options.length > 0 && this.state.cat2Options.length > 0 && this.state.cat3Options.length > 0 ?
                        <Form onSubmit={this.saveData}>
                            <Row className="mb-2">
                                <Col className="col-2 d-flex align-items-center">
                                    {this.state.type.toString() === '1' ?
                                        <Badge color="danger" className="p-2" style={{ fontSize: '85%' }}> <FormattedMessage id="StaticFields.Internal" /> </Badge>
                                        :
                                        <Badge color="success" className="p-2" style={{ fontSize: '85%' }}> <FormattedMessage id="StaticFields.Support" /> </Badge>
                                    }
                                </Col>
                                <Col>
                                    <CustomSelect
                                        required={true}
                                        isDisabled={this.state.allReadOnly || this.state.ticketClosed}
                                        placeholder={<FormattedMessage id="StaticFields.ChooseStatus" />}
                                        value={this.state.status}
                                        options={statusOptions}
                                        onChange={this.handleStatus.bind(this, 'status')}
                                    />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        required={true}
                                        isDisabled={this.state.allReadOnly || this.state.ticketClosed}
                                        placeholder={<FormattedMessage id="StaticFields.ChooseCategory1" />}
                                        value={this.state.category1}
                                        options={this.state.cat1Options}
                                        onChange={this.handleChangeSelect.bind(this, 'category1')}
                                    />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        isDisabled={this.state.allReadOnly || this.state.ticketClosed ? this.state.allReadOnly || this.state.ticketClosed : this.state.category1 === '' ? true : false}
                                        placeholder={<FormattedMessage id="StaticFields.ChooseCategory2" />}
                                        value={this.state.category2}
                                        options={this.state.category1 && this.state.category1.value ? this.state.cat2Options.filter(o => o.lvl === this.state.category1.value) : null}
                                        onChange={this.handleChangeSelect.bind(this, 'category2')}
                                    />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        isDisabled={this.state.allReadOnly || this.state.ticketClosed ? this.state.allReadOnly || this.state.ticketClosed : this.state.category2 === '' || this.state.category2 === null ? true : false}
                                        placeholder={<FormattedMessage id="StaticFields.ChooseCategory3" />}
                                        value={this.state.category3}
                                        options={this.state.category2 && this.state.category2.value ? this.state.cat3Options.filter(o => o.lvl === this.state.category2.value) : null}
                                        onChange={this.handleChangeSelect.bind(this, 'category3')}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <InputGroup size="sm" className="text-right">
                                            <Input
                                                required
                                                type="text"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                                readOnly={this.state.allReadOnly || this.state.ticketClosed}
                                            />
                                        </InputGroup>
                                    </div>                                    
                                </Col>

                                <Authorization
                                    perform="ticketDetails:send"
                                    yes={() => (
                                        <Col className="col-1 d-flex align-items-center justify-content-end">
                                            {!this.state.allReadOnly && !this.state.ticketClosed ?
                                                this.state.status && this.state.status.value && this.state.status.value.includes('5') ?
                                                    <div>
                                                        <Button color="primary" className="btn-sm" onClick={(e) => this.handleTicket(e, 5)}> <FormattedMessage id="StaticFields.Close" /> </Button>
                                                    </div>
                                                : 
                                                    <div>
                                                        <Button color="primary" type="submit" className="btn-sm"> <FormattedMessage id="StaticFields.Send" /> </Button>
                                                    </div>
                                            : this.props.ticketClosed ?
                                                <div>
                                                    <Button color="primary" className="btn-sm" onClick={(e) => this.handleTicket(e, null)}> <FormattedMessage id="StaticFields.Reopen" /> </Button>
                                                </div>
                                            : '' }
                                        </Col>
                                    )} no={() => <div></div>}
                                />
                            </Row>

                            <Authorization
                                perform="ticketDetails:send"
                                yes={() => (
                                    <Row>
                                        <Col>
                                            {this.state.type.toString() === '1' ?
                                                <>
                                                    <i className="fas fa-exclamation-triangle text-warning mr-2" />
                                                    <FormattedMessage id="StaticFields.TicketNotSubmited" />                                            
                                                </>
                                            : ''}
                                        </Col>
                                    </Row>
                                )}
                                no={() => <div></div>}
                            />
                        </Form>
                    : ''}
                        
                </Collapse>
                
            </BlockUi>
        );
    }
}

export default StaticFields;