import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader } from 'reactstrap';
import { authContext } from '../../Utils/AdalConfig';
import { getAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Base/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import { StyledCard } from '../Base/Common/CommonUIComponents';
//import { msalApp } from '../../Utils/auth-utils'


export class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            ComplexData: null,
            user: {}
        };

    }

    componentDidMount() {
        this.getHotels();
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((hotel) => {
                    var object = {
                        value: hotel.EntityUID,
                        label: hotel.EntityName
                    };

                    options.push(object);
                })

                this.setState({
                    hotelOptions: options,
                    block: false
                });

               
            }
        }, "/api/HelpDesk/tickets/UserAllowedEntities");
    }

    render() {
        
        return (


         <div>

                <Form className="sm small"  >
                    <StyledCard icon='fas fa-id-card' title={<FormattedMessage id="Profile.Profile" />}> 
                   
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="User" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.User" /> </Label>
                                        <Col sm={10}>

                                        <Input type="text" bsSize="sm" name="User" value={authContext.getCachedUser().userName} readOnly id="User" >

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="Email" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.Email" /> </Label>
                                        <Col sm={10}>

                                        <Input type="email" bsSize="sm" name="Email" value={authContext.getCachedUser().profile.email} readOnly id="User" >

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="FirstName" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.FirstName" /> </Label>
                                        <Col sm={10}>

                                        <Input type="text" bsSize="sm" name="FirstName" value={authContext.getCachedUser().profile.given_name} readOnly id="User" >

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>

                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="FamilyName" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.FamilyName" /> </Label>
                                        <Col sm={10}>

                                        <Input type="text" bsSize="sm" name="FamilyName" value={authContext.getCachedUser().profile.family_name} readOnly id="User" >

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                    
                    </StyledCard>
                    <Row className="mt-2">
                        <Col className="pr-1">
                            <StyledCard icon='fas fa-hotel' title={<FormattedMessage id="Profile.Property" />}> 
                           
                                    <Table className="table-sm table-borderless">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th>#</th>
                                                <th> <FormattedMessage id="Profile.Property" /> </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.hotelOptions && this.state.hotelOptions.map((item, key) =>
                                                <tr key={key} >
                                                    < th scope="row" > {key}</th>
                                                    <td >{item.label}</td>
                                                </tr>
                                            )}


                                        </tbody>
                                </Table>
                            </StyledCard>
                        </Col>
                        <Col className="pl-1">
                            <StyledCard icon='fas fa-users' title={<FormattedMessage id="Profile.AccessGroups" />}> 
                          
                                    <Table className="table-sm table-borderless">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th>#</th>
                                                <th>Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {authContext.getCachedUser().profile.groups && authContext.getCachedUser().profile.groups.map((item, key) =>
                                            <tr key={key}>
                                                < th scope="row" > {key}</th>
                                                <td >  <FormattedMessage id={'generic.accessGroup.' + item} /> </td>
                                            </tr>
                                        )}


                                        </tbody>
                                    </Table>

                                </StyledCard>
                            
                        </Col>
                    </Row>



                </Form>

            </div>
        );
    }
};