import React, { Component } from 'react';
import { Row, Col, Badge } from 'reactstrap';
import { getContractStatus } from '../Base/Common/ReferenceDataFunctions';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import Notification from './Notification';
import BlockUi from 'react-block-ui';
import moment from 'moment';

export class ClientSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientSummary: null,
            collapse: false,
            modal: false,
            notifSelected: null,
            hotelNotif: null
        }
    }

    componentDidMount() {
        this.setState({
            clientSummary: this.props.clientSummary
        })
    }

    
    toggleCollapse = () => {
        this.setState(prevState => ({
            collapse: !prevState.collapse
        }));
    }
       
    toggleModal = (notification, hotel) => {
        this.setState(prevState => ({
            notifSelected: notification,
            hotelNotif: hotel,
            modal: !prevState.modal
        }));
    }

    getStatus = (num) => {
        var description = num;

        getContractStatus().map((status) => {
            if (status.value === num) {
                description = status.label;
            }
        })

        return description;
    }

    getGeneralNotifications = (clientSummary) => {
        var generalNotif = [];

        clientSummary.map((client) => {
            client && client.Notifications && client.Notifications.map((notif) => {
                if (notif.Type === 1) {
                    if (generalNotif.find(gn => gn.ID === notif.ID) === undefined) {
                        generalNotif.push(notif);
                    }
                }
            })
        })

        return generalNotif;
    }


    render() {
        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <StyledCard icon='fas fa-file-contract' text='Channel Reservation'>
                    <Row>
                        <Col className="col-7"> {<FormattedMessage id="ClientSummary.LastReply" />} </Col>
                        <Col className="col-5"> {this.props.lastReply} </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="col-7"> {<FormattedMessage id="ClientSummary.LastResponse" />} </Col>
                        <Col className="col-5"> {this.props.lastResponse } </Col>
                    </Row>

                    <hr />
                    {this.props.clientSummary && this.props.clientSummary.map((sum, index) =>
                        sum ?
                            <div key={index}>
                                <Row onClick={this.toggleCollapse} style={{ cursor: 'pointer' }} >
                                    <Col>
                                        <a href={`#${index}`} data-toggle="collapse" aria-expanded="false" className="dropdown-toggle hotelName">
                                            <i className="fas fa-hotel mr-1" />
                                            {sum.Name}
                                            {sum.Notifications && sum.Notifications.filter((notif) => notif.Type === 2).length > 0 ?
                                                < Badge color="success" className="ml-2" style={{ borderRadius: '50px'}}> {sum.Notifications.filter((notif) => notif.Type === 2).length}</Badge>
                                            :''}
                                        </a>
                                    </Col>
                                </Row>
                                <hr />

                                <div id={index} className="collapse pl-3" key={index}>  
                                    {sum.Contracts && sum.Contracts.map((contract, i) => contract ?
                                        <div key={i}>
                                            <Row>
                                                <Col> {contract.Description} </Col>
                                            </Row>
                                            <hr className="my-2 border-host" />

                                            <Row>
                                                <Col className="col-4"> {<FormattedMessage id="ClientSummary.Status" />} </Col>
                                                <Col className="col-8">
                                                    {contract.Status == 10 ? 
                                                        <span>
                                                            <Badge color='danger' className="p-1"> {this.getStatus(contract.Status)}</Badge>
                                                            {<FormattedMessage id="ClientSummary.ExpiredAt" />} {moment(contract.EndDate).format("DD-MM-YYYY")}
                                                        </span>
                                                        :
                                                        <span>
                                                            <Badge color='success' className="p-1"> {this.getStatus(contract.Status)}</Badge>
                                                            {<FormattedMessage id="ClientSummary.Until" />} {moment(contract.EndDate).format("DD-MM-YYYY")}
                                                        </span>
                                                    }
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    : '')}

                                    {sum.Notifications && sum.Notifications.length > 0 && sum.Notifications.filter((notif) => notif.Type === 2).length > 0 ?
                                        <div>
                                            <Row style={{ height: '20px', marginTop: '20px' }}>
                                                <Col> {<FormattedMessage id="ClientSummary.Notifications" />} </Col>
                                            </Row>
                                            <hr className="my-2  border-host" />
                                            {sum.Notifications.map((notif, i) => notif && notif.Type === 2 ?
                                                <div key={i}>
                                                    <Row>
                                                        <Col> {notif.Title}</Col>
                                                        <Col className="col-2">
                                                            <i onClick={() => this.toggleModal(notif, sum.Name)} className="fas fa-info-circle" style={{ cursor: 'pointer' }}></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ fontSize: '14px' }}> {moment(notif.UpdatedOn).format("DD-MM-YYYY")} </Col>
                                                    </Row>
                                                    <hr />
                                                </div>
                                            : '')}
                                        </div>
                                    : ''}

                                    <div>
                                        <Row style={{ height: '20px', marginTop: '20px' }}>
                                            <Col> {<FormattedMessage id="ClientSummary.Contacts" />} </Col>
                                        </Row>
                                        <hr className="my-2 border-host" />
                                        <Row>
                                            <Col className="col-4"> {<FormattedMessage id="ClientSummary.Name" />} </Col>
                                            <Col className="col-8"> {sum.Name} </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-4"> {<FormattedMessage id="ClientSummary.Email" />} </Col>
                                            <Col className="col-8">
                                                <a href={`mailto:${sum.Email1}`} >{sum.Email1} </a>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        : ''
                    )}
                    {this.props.clientSummary && this.getGeneralNotifications(this.props.clientSummary).length > 0 ?
                        <div>
                            <Row onClick={this.toggleCollapse} style={{ cursor: 'pointer' }} >
                                <Col>
                                    <a href={`#notif`} data-toggle="collapse" aria-expanded="false" className="dropdown-toggle hotelName"> 
                                        {<FormattedMessage id="ClientSummary.GeneralNotifications" />}
                                        <Badge color="success" className="ml-2" style={{ borderRadius: '50px' }}> {this.getGeneralNotifications(this.props.clientSummary).length}</Badge>
                                    </a>
                                </Col>
                            </Row>
                            <hr />

                            <div id="notif" className="collapse">
                                {this.getGeneralNotifications(this.props.clientSummary).map((notif, i) =>
                                    <div key={i}>
                                        <Row>
                                            <Col> {notif.Title} </Col>
                                            <Col className="col-2">
                                                <i onClick={() => this.toggleModal(notif, null)} className="fas fa-info-circle" style={{ cursor: 'pointer'}}></i>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ fontSize: '14px' }}> {moment(notif.UpdatedOn).format("DD-MM-YYYY")} </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                )}
                            </div>
                        </div>
                    : ''}
                </StyledCard>

                <Notification
                    modal={this.state.modal}
                    toggleModal={this.toggleModal}
                    notification={this.state.notifSelected}
                    hotelNotif={this.state.hotelNotif}
                />
            </BlockUi>
        );
    }
}

export default ClientSummary;