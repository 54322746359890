import React, { Component } from 'react';
import { Button, ModalHeader, Modal, ModalBody, ModalFooter, Col, Row, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';


export class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: null,
            text: null
        }
    }

    componentDidMount() {

    }


    render() {
        var notification = { ...this.props.notification }
        return (
            <div>
                {notification ?
                    <Modal isOpen={this.props.modal} toggle={()=>this.props.toggleModal(null, null)} className="modal-xl">
                        <ModalHeader>
                            {notification.Title}
                        </ModalHeader>

                        <ModalBody>
                            <Row>
                                {this.props.hotelNotif ?                                
                                    <Col className="">
                                        <b> {<FormattedMessage id="Notification.Hotel" />} </b> {this.props.hotelNotif}
                                    </Col>
                                : ''}
                                <Col className="" style={{ textAlign: 'right'}}>
                                    <Label> <b> {<FormattedMessage id="Notification.Date" />}</b> {moment(notification.UpdatedOn).format("DD-MM-YYYY")}</Label> 
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col>
                                    {ReactHtmlParser(notification.Text)}
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="secondary" onClick={()=>this.props.toggleModal(null, null)}>{<FormattedMessage id="Notification.Close" />}</Button>
                        </ModalFooter>
                    </Modal>
                :''}
            </div>
        )
    }
}
export default Notification;