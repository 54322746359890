import { UserAgentApplication } from 'msal';

export const msalApp = new UserAgentApplication({
    auth: {
        //tenant: 'ba6750d3-d0c9-47c5-b8f6-989c1e0c0312',
        clientId: 'a8063c4f-3c0c-4c1b-8a29-98a3c117a22e',
        authority: 'https://login.microsoftonline.com/ba6750d3-d0c9-47c5-b8f6-989c1e0c0312',
        validateAuthority: false,
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: 'https://tickets.dev.heytravel.net'
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }, system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case UserAgentApplication.LogLevel.Error:
                        console.error(message)
                        return
                    case UserAgentApplication.LogLevel.Info:
                        console.info(message)
                        return
                    case UserAgentApplication.LogLevel.Verbose:
                        console.debug(message)
                        return
                    case UserAgentApplication.LogLevel.Warning:
                        console.warn(message)
                }
            }
        }
    }
});

export const Graph_Req = {
    LOGIN: {
        scopes: ['openid','profile']
    }
}

