import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button, InputGroup, InputGroupAddon, Input, Form, Badge } from 'reactstrap';
import { getTicketStatus } from './Base/Common/ReferenceDataFunctions';
import { StyledCard } from './Base/Common/CommonUIComponents';
import { CustomTable } from './Base/Common/CustomTable';
import CustomToast from './Base/Common/CustomToast';
import NewTicket from './Tickets/NewTicket';
import { FormattedMessage, injectIntl } from 'react-intl';
import ClientSummary from './ClientSummary/ClientSummary';
import { getAPI } from "./Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/pt'

class Home extends Component {
  static displayName = Home.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            ticketList: [],
            ticketNr: -1,
            modal: false,
            cat1Options: [],
            cat2Options: [],
            cat3Options: [],
            brands: [],
            start: 0,
            limit: 0,
            total: null,
            clientSummary: null
        }
    }

    componentDidMount() {
        this.getBrands();
        this.getCategories();
        this.getClientSummary(); 

        this.getTickets("?Dummy=0&Start=" + this.state.start + "&Limit=" + this.state.limit);

        if (window.location.search) {
            this.setState({ modal: true })
        }
    }

    getBrands = () => {
        this.setState({ blockBrands: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, blockBrands: false });

                return;
            }
            if (data) {
                if (data.success === true) {
                    var list = [];

                    data.data && data.data.forEach(brand => {
                        if (brand.Active) {
                            var object = {
                                value: brand.ID,
                                label: brand.Description,
                                code: brand.Code,
                                extType: brand.ExtType
                            };

                            list.push(object);
                        }
                    })

                    this.setState({ brands: list, blockBrands: false });
                }
            }
            this.setState({ blockBrands: false });
        }, "/api/HelpDesk/Brand/V1");
    }

    getClientSummary = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.success === true) {
                    var list = [];

                    data.data && data.data.forEach(el => {
                        list.push(el.ClientInfo);
                    })

                    this.setState({ clientSummary: list, block: false });
                }
            }
            this.setState({ block: false });
        }, "/api/HelpDesk/tickets/UserAllowedEntities");
    }

    getCategories = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        code: category.Code,
                        extType: category.ExtType
                    };

                    options.push(object);
                })

                this.setState({ cat1Options: options });
            }
        }, "/api/HelpDesk/Categories/V1");

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        lvl: category.CatLvl1Id,
                        code: category.Code
                    };

                    options.push(object);
                })

                this.setState({ cat2Options: options });
            }
        }, "/api/HelpDesk/Categories/V2");

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        lvl: category.CatLvl2Id,
                        code: category.Code
                    };

                    options.push(object);
                })

                this.setState({ cat3Options: options, block: false });
            }
        }, "/api/HelpDesk/Categories/V3");
    }

    getTickets(body) {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({
                    ticketList: data.data,
                    block: false,
                    total: data.total
                });
            }
        }, "/api/HelpDesk/ticket" + body);
    }

    searchTicket = (event) => {
        event.preventDefault();
        this.setState({ start: 0 });
        var params = '';

        if (this.state.ticketNr !== '') {
            params = "?Dummy=0&Nr=" + this.state.ticketNr + "&Id=-1&Type=-1&Status=-1&Priority=-1&ProfileUid=" +
                "&CategoryLvl1=-1&CategoryLvl2=-1&CategoryLvl3=-1&Start=" + this.state.start + "&Limit=" + this.state.limit;
        }

        this.getTickets(params);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
       
    toggleCollapse = () => {
        this.setState(prevState => ({
            collapse: !prevState.collapse
        }));
    }

    getStatus = (num) => {
        var description = num;

        this.state.contractStatus.map((status) => {
            if (status.value === num) {
                description = status.label;
            }
        })

        return description;
    }

    getLastReceviedMsg = () => {
        var time = '';
        var list = this.state.ticketList ? this.state.ticketList.filter((ticket) => ticket.Status == 3) : '';

        if (list && list.length > 0) {
            time = list.slice() ? moment(list.slice().reduce((a, b) => a.UpdatedOn > b.UpdatedOn ? a : b).UpdatedOn).locale(this.props.intl.locale).fromNow() : '';
        }

        return time;
    }

    getLastSentMsg = () => {
        var time = '';
        var list = this.state.ticketList ? this.state.ticketList.filter((ticket) => [1, 2, 4, 6, 8].includes(ticket.Status)) : '';

        if (list && list.length > 0) {
            time = list.slice() ? moment(list.slice().reduce((a, b) => a.UpdatedOn > b.UpdatedOn ? a : b).UpdatedOn).locale(this.props.intl.locale).fromNow() : '';
        }

        return time;
    }

    render() {
        function Crt(props) {
            const numColor = props.numColor ? props.numColor : '#6c757d';
            return <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="text-uppercase text-muted small">{props.text}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right" style={{ color: numColor }}>
                            <h3><div className="text-value-xl"> {props.value}</div></h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        }

        const columns = [
            {
                dataField: 'Type',
                text: this.props.intl.formatMessage({ id: "Home.Type" }),
                sort: true,
                formatter: (cell, row) => row.Type == 2 ? <i className="fas fa-globe"></i> : <i className="far fa-hourglass" style={{ color: '#ffc107' }}></i>,
                style: { textAlign: 'center', verticaAlign: 'middle'}
            },{
                dataField: 'ProfileName',
                text: this.props.intl.formatMessage({ id: "Home.Hotel" }),
                sort: true
            },
            {
                dataField: 'Nr',
                text: this.props.intl.formatMessage({ id: "Home.Number" }),
                sort: true
            }, 
            {
                dataField: 'Description',
                text: this.props.intl.formatMessage({ id: "Home.Description" }),
                sort: true
            },
            {
                dataField: 'ExtType',
                text: this.props.intl.formatMessage({ id: "Home.Brand" }),
                sort: true,
                formatter: (cell, row) => {
                    const brand = this.state.brands.find((b) => b.extType == cell);

                    return brand?.label || cell
                }
            },
            {
                dataField: 'CategoryLvl1',
                text: this.props.intl.formatMessage({ id: "Home.Category1" }),
                sort: true,
                formatter: (cell, row) => this.state.cat1Options.map((cat) => cat.value == row.CategoryLvl1 ? cat.label : '')
            }, {
                dataField: 'CategoryLvl2',
                text: this.props.intl.formatMessage({ id: "Home.Category2" }),
                sort: true,
                formatter: (cell, row) => this.state.cat2Options.map((cat) => cat.value == row.CategoryLvl2 ? cat.label : '')
            }, 
            {
                dataField: 'UpdatedOn',
                text: this.props.intl.formatMessage({ id: "Home.UpdatedOn" }),
                sort: true,
                formatter: (cell, row) => <div>{cell} {moment(moment(), "YYYY-MM-DD").diff(moment(cell, "YYYY-MM-DD"), 'days') <= 2 ? <i style={{ color: 'orange' }} className="far fa-bell ml-2" /> : ''}</div>
            },
            {
                dataField: 'Status',
                text: this.props.intl.formatMessage({ id: "Home.Status" }),
                sort: true,
                formatter: (cell, row) => {
                    if (cell !== null) {
                        const status = getTicketStatus().filter(el => el.value !== '-1').find(status => status.value.includes(cell));

                        return <Badge className="p-1 px-2 shadow text-white" color={status && status.color} >
                            {status && status.label}
                        </Badge>
                    }
                }
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push({
                    pathname: '/TicketDetail/' + row.ID
                });
            }
        };

        return (
            <BlockUi tag="div" blocking={this.state.block || this.state.blockBrands}>
                <div style={{ zIndex: 1, right: 15, top: 58 }} className="position-fixed" >
                    {this.state.clientSummary && this.state.clientSummary.map((sum, key) => sum && sum.Notifications && sum.Notifications.map((notif,k) => notif.Type == 1 ?
                        <CustomToast title={sum.Name} body={''} show={true} icon={'fas fa-bullhorn'} key={key+'-'+k}>
                            <Row>
                                <Col>
                                    {notif.Title}
                                </Col>
                            </Row>
                        </CustomToast>
                        : ''))}
                    </div>

                <Row className="mb-2">
                    <Col>
                        <Crt value={this.state.ticketList && this.state.ticketList.filter((ticket) => ticket.Status !== 5).length} text={<FormattedMessage id="Home.OpenTickets" />}></Crt>
                    </Col>
                    <Col>
                        <Crt value={this.state.ticketList && this.state.ticketList.filter((ticket) => ticket.Status == 3).length} text={<FormattedMessage id="Home.TicketsSupportReply" />}></Crt>
                    </Col>
                    <Col>
                        <Crt value={this.state.ticketList && this.state.ticketList.filter((ticket) => [4, 6, 8].includes(ticket.Status)).length} text={<FormattedMessage id="Home.TicketsResent" />}></Crt>
                    </Col>
                   
                    <Col>
                        <Crt value={this.state.ticketList && this.state.ticketList.filter((ticket) => ticket.Type == 1).length} text={<FormattedMessage id="Home.TicketsHotelSide" />} numColor='#ffc107'></Crt>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-9">

                        <Row>
                            <Col>
                                <StyledCard icon='fas fa-ticket-alt' title={<FormattedMessage id="Home.OpenTickets" />} error={this.state.error}>
                                    <Form onSubmit={this.searchTicket}>
                                        <Row className="mb-2">
                                            <Col className="col-2">
                                                <InputGroup size="sm text-right " >
                                                    <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-ticket-alt"></span></span></InputGroupAddon>
                                                    <FormattedMessage id="Home.TicketNr">
                                                        {
                                                            placeholder => <Input className="border-left-0" type="text" name="ticketNr" maxLength="20" id="ticketNr" placeholder={placeholder} onChange={this.handleChange} value={this.state.ticketId} />
                                                        }
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                            <Col className="text-right mb-1">
                                                <Button type="submit" className="btn-sm btn-host mr-2"><i className="fas fa-search "></i></Button>
                                                <Button className="btn-sm btn-host" onClick={this.toggleModal}><i className="fas fa-plus"></i></Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    {this.state.cat1Options.length > 0 && this.state.cat2Options.length > 0 && this.state.cat3Options.length > 0 && !this.state.blockBrands ?
                                        <CustomTable
                                            data={this.state.ticketList}
                                            columns={columns}
                                            totalSize={this.state.total}
                                            selectRow={selectRow}
                                            remote={false}
                                            showTotal={true}
                                            rowStyle={{ cursor: 'pointer' }}
                                            keyField="ID"
                                        />
                                    : ''}
                                </StyledCard>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-3">
                        <ClientSummary
                            lastReply={this.getLastReceviedMsg()}
                            lastResponse={this.getLastSentMsg()}
                            clientSummary={this.state.clientSummary}
                        />
                    </Col>
                </Row>

                {this.state.cat1Options.length > 0 && this.state.cat2Options.length > 0 && this.state.cat3Options.length > 0 && this.state.brands ?
                    <NewTicket
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        history={this.props.history}
                        cat1Options={this.state.cat1Options}
                        cat2Options={this.state.cat2Options}
                        cat3Options={this.state.cat3Options}
                        brands={this.state.brands}
                    />
                :''}
        </BlockUi>
    );
  }
}

export default injectIntl(Home)