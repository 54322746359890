import React, { Component } from 'react';
import { Row, Col, CustomInput, Badge, Label, FormGroup } from 'reactstrap';
import { getContractStatus } from '../Base/Common/ReferenceDataFunctions';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { getAPI } from '../Base/API';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export class ClientFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            hotelOptions: [],
            hotel: null,
            clientInfo: null,
        }
    }

    componentDidMount() {
        this.getHotels();
    }

    getClientInfo = (entityUid) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.success === true) {
                    this.setState({ clientInfo: data.data, block: false });
                }
                
            }
        }, "/api/HelpDesk/ClientInfo?entityUid=" + entityUid);
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((hotel) => {
                    var object = {
                        value: hotel.EntityUid,
                        label: hotel.EntityName
                    };

                    options.push(object);
                })

                this.setState({
                    hotelOptions: options,
                    hotel: options[0]
                });

                this.getClientInfo(options[0].value);
            }
            this.setState({ block: false });
        }, "/api/HelpDesk/tickets/UserAllowedEntities");
    }

    handleChangeSelect = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption,
            entityUid: selectedOption.value
        });

        this.getClientInfo(selectedOption.value);
    };

    getStatus = (num) => {
        var description = num;

        getContractStatus().map((status) => {
            if (status.value === num) {
                description = status.label;
            }
        })

        return description;
    }


    render() {
        var clientInfo = { ...this.state.clientInfo }
        return (
            <StyledCard icon='fas fa-file-alt' title={<FormattedMessage id="ClientFile.ClientFile" />} block={this.state.block} error={this.state.error}>
                {clientInfo !== null ?
                    <div>
                        <FormGroup row>
                            <Label sm={1}>{<FormattedMessage id="ClientFile.Entity" />} </Label>
                            <Col sm={3}>
                                <CustomSelect
                                    placeholder={<FormattedMessage id="ClientFile.Hotel" />}
                                    value={this.state.hotel}
                                    options={this.state.hotelOptions}
                                    onChange={this.handleChangeSelect.bind(this, 'hotel')}
                                />
                            </Col>
                        </FormGroup>
                        <hr/>
                        <h5 className="mt-4">{<FormattedMessage id="ClientFile.Contacts" />} </h5>
                        <hr className="my-1 border-host" />
                        <Row className="mb-2">
                            <Col className="col-2"><b> {<FormattedMessage id="ClientFile.LegalName" />} </b></Col>
                            <Col className="col-4">{clientInfo.LegalName}</Col>
                            <Col className="col-2"><b>{<FormattedMessage id="ClientFile.VAT" />}</b></Col>
                            <Col className="col-4">{clientInfo.Vatnumber}</Col>
                        </Row>
                        <Row className="mb-4">
                            <Col className="col-2"><b>{<FormattedMessage id="ClientFile.Address" />}</b></Col>
                            <Col className="col-4">{clientInfo.Address1} {clientInfo.Address2} , {clientInfo.Zipcode} {clientInfo.Town}</Col>
                            <Col className="col-2"><b>{<FormattedMessage id="ClientFile.Email" />}</b></Col>
                            <Col className="col-4">{clientInfo.Email1}</Col>
                        </Row>

                        {clientInfo.Contracts && clientInfo.Contracts.map((contract) =>
                            <div>
                                <h5> {contract.Description}</h5>
                                <hr className="my-1 border-host" />
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b>{<FormattedMessage id="ClientFile.Code" />} </b> 
                                    </Col>
                                    <Col>
                                        {contract.Code}
                                    </Col>
                                    <Col className="col-2">
                                        <b>{<FormattedMessage id="ClientFile.Description" />} </b>
                                    </Col>
                                    <Col>
                                        {contract.Description}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b>{<FormattedMessage id="ClientFile.ContractDate" />} </b>
                                    </Col>
                                    <Col>
                                        {moment(contract.StarDate).format('DD-MM-YYYY')} {<FormattedMessage id="ClientFile.to" />} {moment(contract.EndDate).format('DD-MM-YYYY')}
                                    </Col>
                                    <Col className="col-2">
                                        <b> {<FormattedMessage id="ClientFile.Status" />}</b>
                                    </Col>
                                    <Col>
                                        <Badge color={contract.Status == 10 ? 'danger' : 'success'} className="p-1"> {this.getStatus(contract.Status)}</Badge>
                                    </Col>
                                </Row>
                               
                                <Row className="mb-2">
                                    <Col>
                                        {contract.ElectronicInvoice ? <i style={{ color: 'green' }} className="fas fa-check mr-1" /> : <i className="fas fa-ban mr-1"/>}
                                        <FormattedMessage id="ClientFile.ElectronicInvoice" />
                                    </Col>
                                    <Col>
                                        {contract.SendByEmail ? <i style={{ color: 'green' }} className="fas fa-check mr-1" /> : <i className="fas fa-ban mr-1" />}
                                        <FormattedMessage id="ClientFile.SendByEmail" />
                                    </Col>
                                    <Col>
                                        {contract.Renewal ? <i style={{ color: 'green' }} className="fas fa-check mr-1" /> : <i className="fas fa-ban mr-1" />}
                                        <FormattedMessage id="ClientFile.Renewal" />
                                    </Col>
                                </Row>

                                <div className="my-2"/>
                                {contract.IsBankHours ? <div> <b> Time Available: </b> {contract.TimeAvailable} </div> : ''}
                                <h5 className="mt-4">{<FormattedMessage id="ClientFile.ContractServices" />} </h5>
                                <hr className="my-1 border-host" />
                                {clientInfo.Services && clientInfo.Services.map((service) =>
                                    <div>
                                        {service.ContractId === contract.ID ?
                                            <div>
                                                <Row className="mb-2">
                                                        <Col>
                                                            <div><h6>{service.Description}</h6></div>
                                                            <Badge color={service.Description ? 'success' : 'secondary'}>{service.Description ? 'Active' : 'Inactive'}</Badge>
                                                        </Col>
                                                        <Col>
                                                            <div><b> {<FormattedMessage id="ClientFile.CreationDate" />} </b>: {service.CreatedOn}</div>
                                                            <div><b> {<FormattedMessage id="ClientFile.UpdatedDate" />} </b>: {service.UpdatedOn}</div>

                                                        </Col>
                                                        <Col>
                                                            <div><b>{<FormattedMessage id="ClientFile.Code" />}</b> {service.Code}</div>
                                                            {service.Observation ? <div><b>{<FormattedMessage id="ClientFile.Observation" />}</b> {service.Observation}</div> : ''}
                                                    
                                                        </Col>
                                                </Row>
                                                <hr className="my-2 " />
                                                  
                                            </div>
                                        : ''}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                : ''}
            </StyledCard>
        );
    }
}
