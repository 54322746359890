import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';

export function getTicketStatus() {
    const statusOptions = [
        {
            value: '-1',
            label: <FormattedMessage id="ReferenceData.All"/>
        },
        {
            value: '0',
            label: <FormattedMessage id="ReferenceData.Opened" />,
            color: 'primary'
        },
        {
            value: '1,2',
            label: <FormattedMessage id="ReferenceData.Sent" />,
            color: 'warning'
        },
        {
            value: '3',
            label: <FormattedMessage id="ReferenceData.SupportReply" />,
            color: 'info'
        },
        {
            value: '4,6',
            label: <FormattedMessage id="ReferenceData.Resent" />,
            color: 'warning'
        },
        {
            value: '5,7',
            label: <FormattedMessage id="ReferenceData.Closed" />,
            color: 'success'
        },
        {
            value: '8',
            label: <FormattedMessage id="ReferenceData.UnderReview" />,
            color: 'secondary'
        }
    ]

    return statusOptions;
}

export function getContractStatus() {
    const statusOptions = [
        { value: 1, label: <FormattedMessage id="ReferenceData.Valid" /> },
        { value: 2, label: <FormattedMessage id="ReferenceData.Implementation" /> },
        { value: 3, label: <FormattedMessage id="ReferenceData.Warranty" /> },
        { value: 4, label: <FormattedMessage id="ReferenceData.Suspended" /> },
        { value: 10, label: <FormattedMessage id="ReferenceData.WithoutContract" /> }
    ]

    return statusOptions;
}

export function getTicketType() {
    const statusOptions = [
        { value: -1, label: <FormattedMessage id="ReferenceData.All" /> },
        { value: 1, label: <FormattedMessage id="ReferenceData.Internal" /> },
        { value: 2, label: <FormattedMessage id="ReferenceData.Support" /> }
    ]

    return statusOptions;
}