import React, { Component } from 'react';

class DragAndDrop extends Component {
    state = {
        drag: false
    }

    dropRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true })
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter === 0) {
            this.setState({ drag: false })
        }
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: false })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }
    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }


    render() {
        return (
            <div
                style={{ display: 'flex', textAlign: 'center', border: '1px dashed grey', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
                ref={this.dropRef}
            >
                <span>
                <h6 style={{ color: '#a7a7a7' }}>
                    <i className="fas fa-download"></i>
                    <br/>
                    Drop Files Here 
                </h6>
                </span>
                {this.props.children}
            </div>
        )
    }
}
export default DragAndDrop