import BootstrapTable from 'react-bootstrap-table-next';
import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import {  Row, Col } from 'reactstrap';

import { FormattedMessage } from 'react-intl';
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;


export const CustomTable = ({ data, page, sizePerPage, onTableChange, onSizePerPageChange, hidePageListOnlyOnePage, totalSize, columns, shadow, selectRow, showTotal, search, remote, exportCSV, rowStyle, keyField }) => (
    <FormattedMessage id="generic.table.nodata" defaultMessage="No Data Available">
        {placeholder =>
            <FormattedMessage id="generic.table.search" defaultMessage="Search">
                {searchPlaceholder =>
                    <ToolkitProvider
                        keyField={keyField ? keyField : "id"}
                        data={data}
                        columns={columns}
                        search={search}
                        bootstrap4={true}
                    >
                        {
                            props => (

                                <div className={shadow ? 'small shadow' : 'small'}>
                                    {search || exportCSV ?
                                        <Row>
                                            <Col>{search ?
                                                <SearchBar className="form-control-sm" {...props.searchProps} placeholder={searchPlaceholder} />
                                                : ''}
                                            </Col>
                                            <Col>{
                                                exportCSV ? <ExportCSVButton className="btn btn-host btn-sm float-right shadow" {...props.csvProps}><i className="fas fa-file-excel"></i></ExportCSVButton> : ''}

                                            </Col>
                                        </Row>
                                        : ''}
                                    <BootstrapTable {...props.baseProps}
                                        noDataIndication={placeholder}
                                        condensed={true}
                                        bordered={false}
                                        exportCSV={exportCSV}
                                        pagination={paginationFactory({ hidePageListOnlyOnePage: true, hideSizePerPage: false, page, sizePerPage, showTotal: showTotal, totalSize, onSizePerPageChange: onSizePerPageChange })}
                                        onTableChange={onTableChange}
                                        remote={remote}
                                        totalSize={totalSize}
                                        hover
                                        selectRow={selectRow}
                                        rowStyle={rowStyle}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                }
            </FormattedMessage>
                }
   </FormattedMessage>
);
