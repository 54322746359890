import React, { Component } from 'react';
import { Form, Row, Col, Button, InputGroup, InputGroupAddon, Input, Badge } from 'reactstrap';
import { getTicketStatus, getTicketType } from '../Base/Common/ReferenceDataFunctions';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import CustomSelect from '../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import NewTicket from './NewTicket';
import { getAPI } from '../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';

class TicketSearch extends Component {
    static displayName = TicketSearch.name;
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            ticketList: [],
            ticketNr: '',
            type: -1,
            status: -1,
            brand: -1,
            category1: -1,
            category2: -1,
            category3: -1,
            modal: false,
            brands: [],
            cat1Options: [],
            cat2Options: [],
            cat3Options: [],
            hotelOptions: [],
            hotel: -1,
            start: 0,
            limit: 10,
            total: null
        }
    }

    componentDidMount() {
        this.getBrands();
        this.getCategories();
        this.getHotels();

        this.getTickets("?Dummy=0&Start=" + this.state.start + "&Limit=" + this.state.limit);
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((hotel) => {
                    var object = {
                        value: hotel.EntityId,
                        label: hotel.EntityName
                    };

                    options.push(object);
                })

                this.setState({ hotelOptions: options, block: false });
            }
        }, "/api/HelpDesk/tickets/UserAllowedEntities");
    }

    getBrands = () => {
        this.setState({ blockBrands: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, blockBrands: false });

                return;
            }
            if (data) {
                if (data.success === true) {
                    var list = [{ value: -1, label: this.props.intl.formatMessage({ id: "ReferenceData.All" }) }];

                    data.data && data.data.forEach(brand => {
                        if (brand.Active) {
                            var object = {
                                value: brand.ID,
                                label: brand.Description,
                                code: brand.Code,
                                extType: brand.ExtType
                            };

                            list.push(object);
                        }
                    })

                    this.setState({ brands: list, blockBrands: false });
                }
            }
            this.setState({ blockBrands: false });
        }, "/api/HelpDesk/Brand/V1");
    }

    getCategories = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        code: category.Code,
                        extType: category.ExtType
                    };

                    options.push(object);
                })

                this.setState({ cat1Options: options });
            }
        }, "/api/HelpDesk/Categories/V1");

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        lvl: category.CatLvl1Id,
                        code: category.Code
                    };

                    options.push(object);
                })

                this.setState({ cat2Options: options });
            }
        }, "/api/HelpDesk/Categories/V2");

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var options = [];

                data.data.map((category) => {
                    var object = {
                        value: category.ID,
                        label: category.Description,
                        lvl: category.CatLvl2Id,
                        code: category.Code
                    };

                    options.push(object);
                })

                this.setState({ cat3Options: options, block: false });
            }
        }, "/api/HelpDesk/Categories/V3");
    }

    getTickets(body) {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({
                    ticketList: data.data,
                    block: false,
                    total: data.total
                });
            }
        }, "/api/HelpDesk/ticket" + body);
    }

    searchTicket = (event) => {
        event.preventDefault();
        this.setState({ start: 0 });

        var body = "?Dummy=0&Nr=" + (this.state.ticketNr === '' ? -1 : this.state.ticketNr) + "&Id=-1" +
            "&Type=" + (this.state.type === -1 ? -1 : this.state.type.value) +
            "&StatusIds=" + (this.state.status === -1 ? -1 : this.state.status.value) +
            "&Priority=-1" +
            "&ExtType=" + (this.state.brand === -1 ? -1 : this.state.brand.extType) +
            "&ProfileId=" + (this.state.hotel === -1 ? -1 : this.state.hotel.value) +
            "&CategoryLvl1=" + (this.state.category1 === -1 ? -1 : this.state.category1.value) +
            "&CategoryLvl2=" + (this.state.category2 === -1 ? -1 : this.state.category2.value) +
            "&CategoryLvl3=" + (this.state.category2 === -1 ? -1 : this.state.category3.value) +
            "&Start=" + this.state.start +
            "&Limit=" + this.state.limit;

        this.getTickets(body);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleChangeSelect = (name, selectedOption) => {
        if (name === "brand") {
            this.setState({ category1: -1, category2: -1 })
        }
        else if (name === "category1") {
            this.setState({ category2: -1 })
        }

        this.setState({
            [name]: selectedOption
        })
    };

    filterOptions = (options, cat) => {
        var validOptions = [];

        options.map((option) => {
            if (option.lvl === cat || option.value === -1) {
                validOptions.push(option);
            }
        })

        return validOptions;
    };
    
    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        var body = "?Dummy=0" +
            "&Start=" + currentIndex +
            "&Limit=" + sizePerPage;

        this.setState({
            start: currentIndex,
            limit: sizePerPage,
        }, () => { this.getTickets(body) });
    }

    render() {

        const columns = [
            {
                dataField: 'Type',
                text: this.props.intl.formatMessage({ id: "Home.Type" }),
                sort: true,
                formatter: (cell, row) => row.Type == 2 ? <i className="fas fa-globe"></i> : <i className="far fa-hourglass" style={{ color: '#ffc107' }}></i>,
                style: { textAlign: 'center', verticaAlign: 'middle' }
            }, {
                dataField: 'ProfileName',
                text: this.props.intl.formatMessage({ id: "Home.Hotel" }),
                sort: true
            },
            {
                dataField: 'Nr',
                text: this.props.intl.formatMessage({ id: "Home.Number" }),
                sort: true
            }, 
            {
                dataField: 'Description',
                text: this.props.intl.formatMessage({ id: "Home.Description" }),
                sort: true
            },
            {
                dataField: 'ExtType',
                text: this.props.intl.formatMessage({ id: "Home.Brand" }),
                sort: true,
                formatter: (cell, row) => {
                    const brand = this.state.brands.find((b) => b.extType == cell);

                    return brand?.label || cell
                }
            },
            {
                dataField: 'CategoryLvl1',
                text: this.props.intl.formatMessage({ id: "Home.Category1" }),
                sort: true,
                formatter: (cell, row) => this.state.cat1Options.map((cat) => cat.value == row.CategoryLvl1 ? cat.label : '')
            }, {
                dataField: 'CategoryLvl2',
                text: this.props.intl.formatMessage({ id: "Home.Category2" }),
                sort: true,
                formatter: (cell, row) => this.state.cat2Options.map((cat) => cat.value == row.CategoryLvl2 ? cat.label : '')
            }, 
            {
                dataField: 'CreatedOn',
                text: this.props.intl.formatMessage({ id: "Home.CreatedOn" }),
                sort: true,
                formatter: (cell, row) => row.Type == 2 ? cell : ''
            },
            {
                dataField: 'UpdatedOn',
                text: this.props.intl.formatMessage({ id: "Home.UpdatedOn" }),
                sort: true,
                formatter: (cell, row) => <div>{cell} {moment(moment(), "YYYY-MM-DD").diff(moment(cell, "YYYY-MM-DD"), 'days') <= 2 ? <i style={{ color: 'orange' }} className="far fa-bell ml-2" /> : ''}</div>

            },
            {
                dataField: 'Status',
                text: this.props.intl.formatMessage({ id: "Home.Status" }),
                sort: true,
                formatter: (cell, row) => {
                    if (cell !== null) {
                        const status = getTicketStatus().filter(el => el.value !== '-1').find(status => status.value.includes(cell));

                        return <Badge className="p-1 px-2 shadow text-white" color={status && status.color} >
                            {status && status.label}
                        </Badge>
                    }
                }
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push({
                    pathname: '/TicketDetail/' + row.ID
                });
            }
        };

        return (
            <BlockUi>
                <StyledCard icon='fas fa-ticket-alt' title='Tickets' block={this.state.block || this.state.blockBrands} error={this.state.error}>
                    <Form onSubmit={this.searchTicket}>
                        <Row className="mb-2">
                            <Col>
                                <InputGroup size="sm text-right " >
                                    <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-ticket-alt"></span></span></InputGroupAddon>
                                    <FormattedMessage id="ticketSearch.TicketNr">
                                        {
                                            placeholder => <Input className="border-left-0" type="text" name="ticketNr" maxLength="20" placeholder={placeholder} onChange={this.handleChange} value={this.state.ticketId} />
                                        }
                                    </FormattedMessage>
                                </InputGroup>
                            </Col>
                            <Col>
                                <CustomSelect
                                    placeholder={<FormattedMessage id="TicketSearch.Hotel" />}
                                    value={this.state.hotel}
                                    options={this.state.hotelOptions}
                                    onChange={this.handleChangeSelect.bind(this, 'hotel')}
                                />
                            </Col>
                            <Col>
                                <CustomSelect
                                    placeholder={<FormattedMessage id="TicketSearch.ChooseType" />}
                                    value={this.state.type}
                                    options={getTicketType()}
                                    onChange={this.handleChangeSelect.bind(this, 'type')}
                                />
                            </Col>
                            <Col>
                                <CustomSelect
                                    placeholder={<FormattedMessage id="TicketSearch.ChooseStatus" />}
                                    value={this.state.status}
                                    options={getTicketStatus()}
                                    onChange={this.handleChangeSelect.bind(this, 'status')}
                                />
                            </Col>
                            <Col>
                                <CustomSelect
                                    placeholder={<FormattedMessage id="TicketSearch.ChooseBrand" />}
                                    value={this.state.brand}
                                    options={this.state.brands}
                                    onChange={this.handleChangeSelect.bind(this, 'brand')}
                                    isLoading={this.state.blockBrands}
                                />
                            </Col>
                            <Col>
                                <CustomSelect
                                    placeholder={<FormattedMessage id="TicketSearch.ChooseCategory1" />}
                                    value={this.state.category1}
                                    options={this.state.brand && this.state.brand.value !== -1 ? this.state.cat1Options.filter(o => o.extType === this.state.brand.extType) : this.state.cat1Options}
                                    onChange={this.handleChangeSelect.bind(this, 'category1')}
                                    isDisabled={this.state.brand === -1 ? true : false}
                                />
                            </Col>
                            <Col>
                                <CustomSelect
                                    isDisabled={this.state.category1 === -1 ? true : false}
                                    placeholder={<FormattedMessage id="TicketSearch.ChooseCategory2" />}
                                    value={this.state.category2}
                                    options={this.filterOptions(this.state.cat2Options, this.state.category1.value)}
                                    onChange={this.handleChangeSelect.bind(this, 'category2')}
                                />
                            </Col>

                            <Col className="text-right mb-1">
                                <Button type="submit" className="btn-sm btn-host mr-2"><i className="fas fa-search "></i></Button>
                                <Button className="btn-sm btn-host" onClick={this.toggleModal}><i className="fas fa-plus"></i></Button>
                            </Col>
                        </Row>
                    </Form>

                    <br />

                    {this.state.cat1Options.length > 0 && this.state.cat2Options.length > 0 && this.state.cat3Options.length > 0 && !this.state.blockBrands ?
                        <CustomTable
                            data={this.state.ticketList}
                            columns={columns}
                            totalSize={this.state.total}
                            page={this.state.currentPage}
                            sizePerPage={this.state.limit}
                            selectRow={selectRow}
                            onTableChange={this.handleTableChange}
                            remote={true}
                            showTotal={true}
                            rowStyle={{ cursor: 'pointer' }}
                            keyField="ID"
                        >
                        </CustomTable>
                    : ''}
               
                </StyledCard>

                {this.state.modal ?
                    <NewTicket
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        history={this.props.history}
                        cat1Options={this.state.cat1Options.filter(c => c.value !== -1)}
                        cat2Options={this.state.cat2Options.filter(c => c.value !== -1)}
                        cat3Options={this.state.cat3Options.filter(c => c.value !== -1)}
                        brands={this.state.brands.filter(c => c.value !== -1)}
                    />
                : ''}
            </BlockUi>
        );
    }
}

export default injectIntl(TicketSearch)