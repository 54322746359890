import 'bootstrap/dist/css/bootstrap.css';

import { runWithAdal } from 'react-adal';
import { authContext } from './Utils/AdalConfig';



/*
if ('serviceWorker' in navigator) {
    //window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(function () {
        return navigator.serviceWorker.ready;
    }).then(function (registration) {
        registration.pushManager.subscribe({ userVisibleOnly: true }).then(function (sub) {
            var endpointSections = sub.endpoint.split('/');
            var subscriptionId = endpointSections[endpointSections.length - 1];
            console.log('endpoint:', subscriptionId);
        });
    });
    navigator.serviceWorker.ready.then(function (registration) {
        console.log('Service Worker Ready');
    });
}*/


const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {

    // eslint-disable-next-line
    require('./indexApp.js');

}, DO_NOT_LOGIN);

