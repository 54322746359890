const rules = {
    

    'e2c23135-8904-4d26-93fa-d9573ee19d2d': {  // hotel  manager
        static: [
        ]
    },
    'e276ce97-a0b7-40ca-b84a-1250aa808f72': {  // dummy
        static: [
        ]
    },
    'f2441c88-f47d-4092-8dbd-02e8980edee0': { // Ticket Admin
        static: [
            "ticketDetails:send",
            "manageUser:view"
        ]
    }
};

export default rules;