import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import pt from "react-intl/locale-data/pt";

import messages_pt from "../components/Translations/pt.json";
import messages_en from "../components/Translations/en.json";

addLocaleData([...en, ...pt]);

const messagesLang = {
    'pt': messages_pt,
    'en': messages_en
};
const language = navigator.language.split(/[-_]/)[0] === 'pt' ? 'pt' : 'en';  // language without region code
const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);
        
        this.switchToLangEN = () =>
            this.setState({
                locale: 'en', messages: messagesLang['en']
            });

        this.switchToLangPT = () =>
            this.setState({
                locale: 'pt', messages: messagesLang['pt']
            });

        this.currentProperty = (prop) =>
            this.setState({
                currentProperty : prop
            });
        
        this.state = {
            locale: language,
            messages: messagesLang[language],
            switchToLangEN: this.switchToLangEN,
            switchToLangPT: this.switchToLangPT,
            currentProperty: this.currentProperty,
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext };
