import React, { useState } from 'react';
import {  Toast, ToastBody, ToastHeader } from 'reactstrap';

const CustomToast = (props) => {
    const { title, children, icon } = props;
    const [show, setShow] = useState(true);

    const toggle = () => setShow(!show);

    setInterval (() => {
        setShow(false);
    }, 10000);

    return (
        <div style={{ paddingBottom: '8px' }} >
            <Toast isOpen={show} className="shadow" >
                <ToastHeader toggle={toggle} className="text-dark"> <i className={icon}></i>  {title}</ToastHeader>
                <ToastBody>
                    {children}
            </ToastBody>
            </Toast>
        </div>
    );
}

export default CustomToast;


export const CustomToastList = (props) => {
    const { title, children, icon } = props;
    const [show, setShow] = useState(true);

    const toggle = () => setShow(!show);

    return (
        <div className="mb-1 w-100">
            <Toast isOpen={show} className="  shadow" >
                <ToastHeader toggle={toggle} className="text-dark"> <i className={icon}></i>  {title}</ToastHeader>
                <ToastBody>
                    {children}
                </ToastBody>
            </Toast>
        </div>
    );
}
