import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { IntlProviderWrapper } from './Utils/IntlProviderWrapper';
import { addLocaleData } from "react-intl";
import registerServiceWorker from './registerServiceWorker';



import en from "react-intl/locale-data/en";
import pt from "react-intl/locale-data/pt";

addLocaleData([...en, ...pt]);


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

global.channelList = [];

ReactDOM.render(
    
    <IntlProviderWrapper >
        <BrowserRouter basename={baseUrl}>
                <App />
        </BrowserRouter>
        </IntlProviderWrapper>
    ,
    rootElement)



registerServiceWorker(); 