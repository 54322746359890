import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './NavMenu.css';
import { authContext } from '../Utils/AdalConfig';
import { getAPI, postAPI } from "./Base/API"
import BlockUi from 'react-block-ui'

import LanguageSwitch from './Profile/LanguageSwitch';
import Authorization from './Base/Authorization';

import { msalApp} from '../Utils/auth-utils'

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.refreshUserData = this.refreshUserData.bind(this);
        
        this.state = {
            collapsed: true,
            property: '',
            block: false,
            events: [],
            selectedProperty: authContext.getCachedUser().profile.city,
            notification: [{ text: 'Channel Booking with Mapping issues' }, { text: 'Syncronization errors in channel booking' }]
        };
    }

    componentDidMount() {
        this.refreshUserData();
        
    }

    refreshUserData() {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                global.channelList = data.channel;
                this.setState({ user: data, property: data.defaultProperty, block: false });
            }
        }, '/api/User/v1/User');
    }

    changeDefaultProperty(evt) {

        let hotelguid = evt.target.id;
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ selectedProperty: hotelguid, block: false });

                window.location.reload();
            }
        }, '/api/User/v1/User/' + evt.target.id + '/setPreferedHotel');



    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logout() {
        {          
            var fr = document.getElementById("logoutiframe");
            fr.addEventListener('load', (event) => {
                authContext.logOut();
            });
            fr.src = "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" + window.location.origin;
        }
    }

    render() {

        let selProp = this.state.user && this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.find(item => item.property === this.state.user.defaultProperty)
        let propName = selProp ? selProp.propertyName : '';
       

        return (
            <Container className="bg-light">
                <div style={{ display: 'block', visibility : 'hidden' }}>
                <iframe
                        id="logoutiframe"
                        style={{ width: 0, height: 0, border: 0, border: 'none', visibility: 'hidden', position: 'absolute', left:0, top:0 }}
                    width="0"
                    height="0"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    />
                </div>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-white  box-shadow shadow sticky-top  max_navbar_height mb-2 font-weight-bold" style={{ 'borderBottom': '1px solid #21bd3a' }} >
                    <NavbarBrand >Tickets</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav ml-auto">
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="text-secondary">

                                    {authContext.getCachedUser().userName}

                                </DropdownToggle>
                               
                                <DropdownMenu right >
                                    {/*
                                    <BlockUi tag="div" blocking={this.state.block}>
                                        <UncontrolledDropdown direction="left"  >
                                            <DropdownToggle tag="a" className="dropdown-item" caret>
                                                <FormattedMessage id="navbar.Property" />
                                            </DropdownToggle>
                                            <DropdownMenu>

                                                <DropdownItem header><FormattedMessage id="navbar.ChangeProperty" /></DropdownItem>
                                                {this.state.user && this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.map((item, key) =>
                                                    <DropdownItem key={key} active={item.property === this.state.user.defaultProperty ? true : false} id={item.property} onClick={this.changeDefaultProperty.bind(this)}>{item.propertyName}</DropdownItem>
                                                )}

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </BlockUi>
                                    */}
                                    <LanguageSwitch />
                                    <DropdownItem tag={Link} to="/Profile" >
                                        <FormattedMessage id="navbar.Profile" />
                                    </DropdownItem>
                                   
                                    <DropdownItem divider />
                                    <DropdownItem   >
                                        <div onClick={this.logout}><FormattedMessage id="navbar.Logout" /> </div>
                                    </DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>

                       
                            <div className="font_size_xxxs text-secondary position-absolute" style={{ bottom: '1px' }}>
                                {propName}
                            </div>
                        </ul>
                        <ul className="navbar-nav mx-auto">
                            <UncontrolledDropdown nav inNavbar className="customnav-item mr-2">
                                <DropdownToggle nav className="text-secondary " tag={Link} to="/">
                                    <FormattedMessage id="navbar.Dashboard" />
                                </DropdownToggle>

                            </UncontrolledDropdown>


                            <UncontrolledDropdown nav inNavbar className="customnav-item">
                                <DropdownToggle nav className="text-secondary " tag={Link} to="/TicketSearch">
                                    <FormattedMessage id="navbar.Tickets" />
                                </DropdownToggle>

                            </UncontrolledDropdown>


                            <UncontrolledDropdown nav inNavbar className="customnav-item">
                                <DropdownToggle nav className="text-secondary " tag={Link} to="/ClientFile">
                                    <FormattedMessage id="navbar.ClientFile" />
                                </DropdownToggle>
                            </UncontrolledDropdown>

                            {/*<Authorization
                                perform="manageUser:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar className="customnav-item">
                                        <DropdownToggle nav className="text-secondary " tag={Link} to="/ManageUser">
                                            <FormattedMessage id="navbar.ManageUsers" />
                                        </DropdownToggle>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div></div>}
                            />*/}
                        </ul>

                    </Collapse>


                </Navbar>
            </Container>

        );
    }

}       