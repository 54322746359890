import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: 'ba6750d3-d0c9-47c5-b8f6-989c1e0c0312',
    clientId: 'a8063c4f-3c0c-4c1b-8a29-98a3c117a22e',
    endpoints: {
        api: 'a8063c4f-3c0c-4c1b-8a29-98a3c117a22e'

    },
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage',
    logOutUri: window.location.origin
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const getToken = () => {
        return authContext.getCachedToken(authContext.config.clientId);
}
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);