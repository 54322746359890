import React, { Component } from 'react';
import { FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader, Button } from 'reactstrap';
import { getAPI, postAPI, deleteAPI} from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Base/ErrorAlert";
import { CustomTable } from '../Base/Common/CustomTable'
import { handleNotification } from "../Base/Common/Notification";
import { ActiveInactiveStatusBadge, CommonHelper } from "../Base/Common/CommonUIComponents"


class ManageUser extends Component {

    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            blockUserDetails: false,
            blockProperty: false,
            modal : false,
            ComplexData: null,
            user: {},
            selectedUser: {},
            userDetails: {}
        };

    }

    componentDidMount() {
       
        this.searchUser();
        this.setState({ blockProperty: true });
        getAPI(result => {
            const { data, error } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage});

                return;
            }
            if (data) {

                this.setState({ user: data, blockProperty: false});
            }
        }, '/api/User/v1/User');
    }
    
    searchUser() {
        this.setState({ block: true})
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ listUsers: data.users, userDetails: {}, block: false  });
            }
        }, '/api/User/v1/User/getUsers');
    }

    getUserDetails() {
        this.setState({ blockUserDetails: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {

                this.setState({ userDetails: data.user, blockUserDetails: false });
            }
        }, '/api/User/v1/User/getUser/' + this.state.selectedUser.adId);
    }

    reinviteUser() {

        let body = {
            "adId": this.state.selectedUser.adId,
            "name": this.state.selectedUser.name,
            "surname": this.state.selectedUser.surname,
            "mobile": this.state.selectedUser.mobile,
            "email": this.state.selectedUser.email
        }

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ManageUser.NotificationSent" />, <FormattedMessage id="Generic.Success" />);
                this.setState({blockUserDetails: false });
            }
        }, '/api/User/v1/User/reInviteUser', body);
        
    }

    disableUser() {

        this.setState({  blockUserDetails: true});
        postAPI(result => {
            const { data, error, isLoading } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ManageUser.UserDisabled" />, <FormattedMessage id="Generic.Success" />);
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/disableUser/' + this.state.selectedUser.adId);
        
    }

    enableUser() {

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ManageUser.UserEnabled" />, <FormattedMessage id="Generic.Success" />);
                this.setState({blockUserDetails: false });
            }
        }, '/api/User/v1/User/enableUser/' + this.state.selectedUser.adId);

    }

    deleteUser() {

        this.setState({ blockUserDetails: true });
        deleteAPI(result => {
            const { data, error, isLoading } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ManageUser.UserDeleted" />, <FormattedMessage id="Generic.Success" />);
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/deleteUser/' + this.state.selectedUser.adId);

    }

    createUser(event) {

        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id ="ManageUser.UserCreated" />, <FormattedMessage id="Generic.Success" />);
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/addUser', req);
    }

    saveUser(event) {

        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ManageUser.UserUpdated" />, <FormattedMessage id="Generic.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/updateUser', req);
    }

    updateProperties() {
        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.allowedProperties
        postAPI(result => {
            const { data, error, isLoading } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ManageUser.PropertiesUpdated" />, <FormattedMessage id="Generic.Success" />);
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateAllowedProperties', req);
        
    }

    manageList(el, type,event) {

        if (this.state.userDetails[type] === undefined)
            return;

        let newgroup = this.state.userDetails[type].indexOf(el) >= 0 ? this.state.userDetails[type].filter(ar => ar !== el) : [...this.state.userDetails[type], el]

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [type]: newgroup
            }
        }));

    }

    changeUserState(event) {

        let name = event.target.name;
        let value = event.target.value;

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [name]: value
            }
        }));
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageUser.name" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ManageUser.surname" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "ManageUser.email" }),
                sort: true
            },
            {
                dataField: 'mobile',
                text: this.props.intl.formatMessage({ id: "ManageUser.mobile" }),
                sort: true
            },
            {
                dataField: 'accountEnabled',
                text: this.props.intl.formatMessage({ id: "ManageUser.accountStatus" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell}/>
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedUser: row, blockUserDetails : true }, () => this.getUserDetails())
            }
        };
        
        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <form ref={this.form}>
                    <div className="container-fluid content-row">
                        <Row>
                            <Col className="Col-6">   
                                <BlockUi tag="div" blocking={this.state.block}>
                                <Card className="mb-2 border-0 shadow">
                                    <CardHeader className="border-bottom bg-white">
                                        <h6>
                                            <span className="fas fa-users mr-2" />
                                            <FormattedMessage id="ManageUser.users" />
                                            <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.searchUser.bind(this)}>
                                                <i className="fas fa-sync-alt" />
                                            </Button>
                                        </h6>
                                    </CardHeader>
                                    <CardBody>
                                        <CustomTable
                                            data={this.state.listUsers ? this.state.listUsers : []}
                                            columns={columns}
                                            page={this.state.currentPage}
                                            totalSize={this.state.listUsers && this.state.listUsers.length}
                                            onTableChange={this.handleTableChange}
                                            shadow={false}
                                            exportCSV={false}
                                            selectRow={selectRow}
                                            search={true}
                                            remote={false}
                                        />                            
                                    </CardBody>
                                    </Card>
                                </BlockUi>
                                <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                    <Card className="mb-2 border-0 shadow small">
                                        <CardHeader className="border-bottom bg-white">
                                            <h6>
                                                <CommonHelper help={<FormattedMessage id="ManageUser.Help" />} id={'manageruserhelp'} />
                                                <span className="fas fa-id-card mr-1" />
                                                <FormattedMessage id="ManageUser.selectedUser" />
                                                {
                                                    this.state.userDetails.id === undefined ?
                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === null} onClick={this.createUser.bind(this)}><i className="fas fa-plus"></i></Button>
                                                        :
                                                        <div>
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.deleteUser.bind(this)}>
                                                                <i className="fas fa-user-times" />
                                                            </Button>
                                                            {
                                                                this.state.userDetails.accountEnabled ?
                                                                    <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.disableUser.bind(this)} ><i className="fas fa-user-slash"></i></Button>
                                                                    :
                                                                    <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.enableUser.bind(this)}>
                                                                        <i className="fas fa-user-check" />
                                                                    </Button>
                                                            }
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.saveUser.bind(this)}  >
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        </div>
                                                }
                                            </h6>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm small">
                                                        <Label for="User" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.user" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" readOnly name="userPrinciplaName" value={this.state.userDetails.userPrinciplaName ? this.state.userDetails.userPrinciplaName : ''}  id="userPrinciplaName" />                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="Email" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.email" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="email" required email bsSize="sm" name="email" value={this.state.userDetails.email ? this.state.userDetails.email : ''} onChange={this.changeUserState.bind(this)} id="email" />                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FirstName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.name" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="name" value={this.state.userDetails.name ? this.state.userDetails.name : ''} onChange={this.changeUserState.bind(this)} id="name" />                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FamilyName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.surname" /> </Label>
                                                            <Col sm={9}>
                                                                <Input type="text" bsSize="sm" required name="surname" value={this.state.userDetails.surname ? this.state.userDetails.surname: ''} onChange={this.changeUserState.bind(this)} id="surname" />                                            
                                                            </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="mobile" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.mobile" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="mobile" value={this.state.userDetails.mobile ? this.state.userDetails.mobile : ''} onChange={this.changeUserState.bind(this)} id="mobile" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                               
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="stat" className="font-weight-bold" sm={3}> Inv. Status </Label>
                                                        <Col sm={this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ? 7 : 9}>

                                                            <Input type="text" bsSize="sm"  name="stat" defaultValue={this.state.userDetails.status ? this.state.userDetails.status : ''} disabled id="mobile" />
                                                        </Col>
                                                        {this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ?
                                                            <Col>
                                                                <Button className="btn btn-sm btn-host float-right"><i className="far fa-share-square" onClick={this.reinviteUser.bind(this)}></i></Button>
                                                            </Col>
                                                            : ''}
                                                    </FormGroup>
                                                   
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="pr-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails || this.state.blockProperty}>
                                            <Card className="mb-1 h-100  border-0 shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <h6>
                                                        <span className="fas fa-hotel mr-1"></span> <FormattedMessage id="Profile.Property" />
                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateProperties.bind(this)}>
                                                            <i className="fas fa-save" />
                                                        </Button>
                                                    </h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>                                               
                                                        <tbody>
                                                            {
                                                                this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.map((item, key) =>
                                                                    <tr key={key} className={this.state.userDetails && this.state.userDetails.allowedProperties && this.state.userDetails.allowedProperties.filter(el => el === item.property).length > 0 ? 'bg-secondary text-white' : ''} >
                                                                        <th scope="row"> {key}</th>
                                                                        <td onClick={this.manageList.bind(this, item.property, 'allowedProperties')}>{item.propertyName}</td>
                                                                    </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>                         
                </form>
            </BlockUi>
        );
    }
};

export default injectIntl(ManageUser)