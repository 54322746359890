
import axios from 'axios';
import { getToken, adalConfig, authContext } from '../../Utils/AdalConfig';

// Add a request interceptor
const instance = axios.create(adalConfig)
instance.interceptors.request.use((config) => {
    // Check and acquire a token before the request is sent

    return new Promise((resolve, reject) => {
        authContext.acquireToken(adalConfig.endpoints.api, (message, token, msg) => {
            if (!!token) {
                config.headers.Authorization = `Bearer ${token}`;
                resolve(config)
            } else {
                reject(config)
            }
        })
    })
}, function (error) {

    return Promise.reject(error)
})

export function getAPI(callback, url) {

    instance.get(url, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}


export function postAPI(callback, url, body) {

    instance.post(url, body, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function putAPI(callback, url, body) {

    instance.put(url, body, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}


export function patchAPI(callback, url, body) {

    instance.patch(url, body, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + getToken() } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function postMultiPartAPI(callback, url, body) {

    instance.post(url, body, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + getToken(), 'Content-Type': 'multipart/form-data' } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

export function deleteAPI(callback, url) {

    instance.delete(url, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + getToken() } })
        .then(res => callback({ data: res.data, isLoading: false }))
        .catch(err => callback({ error: err, isLoading: false }));
}

