import React, { Component, useEffect } from 'react';
import { Button, ModalHeader, Modal, ModalBody, ModalFooter, Form, Input, Label, FormGroup, Col, Row, CardBody, Badge, Card } from 'reactstrap';
import { handleNotification } from "../Base/Common/Notification";
import { BlankCard } from "../Base/Common/CommonUIComponents";
import CustomSelect from '../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, postAPI } from "../Base/API";
import ReactHtmlParser from 'react-html-parser';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import EditorText from '../Base/Common/EditorText';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";


class NewTicket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockSearch: true,
            filterTroubleshooting: false,
            filterHowTo: false,
            hotel: '',
            type: '',
            category1: '',
            category2: '',
            category3: '',
            title: '',
            brand: '',
            hotelOptions: [],
            cat1Options: this.props.cat1Options,
            cat2Options: this.props.cat2Options,
            cat3Options: this.props.cat3Options,
            brands: this.props.brands,
            editor: {},
            newMessage: ''
        }
    }

    componentDidMount() {
        this.getHotels();

        var editor = this.state.editor;
        const blocksFromHtml = htmlToDraft(this.state.newMessage);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        editor.editorState = EditorState.createWithContent(contentState);

        this.setState({
            editor: editor
        })
    }
        
    getHotels = () => {
        this.setState({ blockSearch: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, blockSearch: false });

                return;
            }
            if (data) {
                if (data.success === true) {
                    var options = [];

                    data.data.map((hotel) => {
                        var object = {
                            value: hotel.EntityUid,
                            label: hotel.EntityName
                        };

                        options.push(object);
                    })

                    this.setState({ hotelOptions: options, blockSearch: false, hotel: options[0] },
                        () => { if (window.location.search) this.fillInFields() }
                    );
                }
            }
        }, "/api/HelpDesk/tickets/UserAllowedEntities");
    }

    fillInFields = () => {
        const params = new URLSearchParams(window.location.search);
        const cat1 = this.state.cat1Options.find(option => option.code === params.get('v1'));
        const cat2 = this.state.cat2Options.find(option => option.code === params.get('v2'));
        const cat3 = this.state.cat3Options.find(option => option.code === params.get('v3'));
        const hotel = this.state.hotelOptions.find(option => option.value === params.get('hotel'));
        const brand = this.state.brands.find(option => option.code === params.get('brand')) || '';
        const title = params.get('title') ? params.get('title') : '';
        const searchquery = params.get('search') ? params.get('search') : '';

        this.setState({
            category1: cat1 ? cat1 : '',
            category2: cat2 ? cat2 : '',
            category3: cat3 ? cat3 : '',
            hotel: hotel ? hotel : '',
            title, searchquery, brand
        })
    }

    handleChangeSelect = (name, selectedOption) => {
        if (name === "brand") {
            this.setState({ category1: '', category2: '', category3: '' });
        }
        else if (name === "category1") {
            this.setState({ category2: '', category3: '' })
        }
        else if (name === "category2") {
            this.setState({ category3: '' })
        }

        this.setState({
            [name]: selectedOption
        })
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    saveTicket = (event) => {
        event.preventDefault();
        var body = {
            ProfileUid: this.state.hotel.value,
            CategoryLvl1: this.state.category1.value,
            CategoryLvl2: this.state.category2.value,
            CategoryLvl3: this.state.category3.value,
            Description: this.state.title,
            Type: 1
        };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.success === false || data.newid < 1) {
                    var response = { error: [{ code: this.props.intl.formatMessage({ id: "Generic.Error" }), message: this.props.intl.formatMessage({ id: "NewTicket.ErrorSavingTheTicket" }) }] };
                    handleNotification(response);
                }
                else {
                    handleNotification(data, <FormattedMessage id="NewTicket.TicketSavedSuccessfully" />, <FormattedMessage id="Generic.Success" />);
                    
                    if (this.state.editor.editorState && this.state.editor.editorState.getCurrentContent().hasText()) {
                        this.sendFirstMsg(data.newid);
                    }
                    else {
                        this.props.toggleModal();
                        this.props.history.push('/TicketDetail/' + data.newid);
                    }
                }

                this.setState({ block: false });
            }
        }, "/api/HelpDesk/ticket", body);
    }

    sendFirstMsg = (ticketId) => {
        this.setState({ block: true });

        var body = {
            ID: 0,
            Text: draftToHtml(convertToRaw(this.state.editor.editorState.getCurrentContent())),
            TicketId: ticketId
        };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.success === false) {
                    handleNotification(data, <FormattedMessage id="TicketDetail.ErrorSendingMessage" />, <FormattedMessage id="Generic.Error" />);
                }
                else {
                    this.setState({ editor: EditorState.createEmpty(), newMessage: '' });
                }
            }
        }, "/api/HelpDesk/tickets/item", body);

        this.setState({ block: false }, () => this.props.history.push('/TicketDetail/' + ticketId));
    }
  
    getConfluenceInfo() {

        let cql = "?cql=(space=CM   ";
        if (this.state.searchquery !== undefined) {
            cql += 'and text ~ "'+this.state.searchquery+'"' 
        }
        if (this.state.filterHowTo) {
            cql += 'and label = "kb-how-to-article" '
        }
        if (this.state.filterTroubleshooting) {
            cql += 'and label = "kb-troubleshooting-article" '
        }
        
        cql += ')'
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({search : data})
            }
        }, '/wiki/rest/api/search'+cql);
    }
    
    onEditorStateChange = (newEditorState, item) => {
        item.editorState = newEditorState;

        this.setState({
            editor: item
        })
    }
    
    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} className="modal-xl">
                <Form onSubmit={this.saveTicket}>
                    <ModalHeader toggle={this.props.toggleModal}><FormattedMessage id="NewTicket.NewTicket"/></ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col>
                                <div style={{ position: 'inherit', zIndex: '50' }}>
                                    <FormGroup row>
                                        <Col>
                                            <Label><FormattedMessage id="NewTicket.Hotel" />*</Label>
                                            <CustomSelect
                                                required={true}
                                                placeholder={<FormattedMessage id="NewTicket.ChooseHotel" />}
                                                value={this.state.hotel}
                                                options={this.state.hotelOptions}
                                                onChange={this.handleChangeSelect.bind(this, 'hotel')}
                                            />
                                        </Col>
                                        <Col>
                                            <Label><FormattedMessage id="NewTicket.Brand" />*</Label>
                                            <CustomSelect
                                                required={true}
                                                placeholder={<FormattedMessage id="NewTicket.Brand" />}
                                                value={this.state.brand}
                                                options={this.state.brands}
                                                onChange={this.handleChangeSelect.bind(this, 'brand')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label><FormattedMessage id="NewTicket.Category1" />*</Label>
                                            <CustomSelect
                                                required={true}
                                                isDisabled={this.state.brand === ''}
                                                placeholder={<FormattedMessage id="NewTicket.ChooseCategory1" />}
                                                value={this.state.category1}
                                                options={this.state.cat1Options.filter(o => o.extType === this.state.brand.extType)}
                                                onChange={this.handleChangeSelect.bind(this, 'category1')}
                                            />
                                        </Col>
                                        <Col>
                                            <Label>{<FormattedMessage id="NewTicket.Category2" />}</Label>
                                            <CustomSelect
                                                isDisabled={this.state.category1 === '' ? true : false}
                                                placeholder={<FormattedMessage id="NewTicket.ChooseCategory2" />}
                                                value={this.state.category2}
                                                options={this.state.cat2Options.filter(o => o.lvl === this.state.category1.value)}
                                                onChange={this.handleChangeSelect.bind(this, 'category2')}
                                            />
                                        </Col>
                                        <Col>
                                            <Label>{<FormattedMessage id="NewTicket.Category3" />}</Label>
                                            <CustomSelect
                                                isDisabled={this.state.category2 === '' ? true : false}
                                                placeholder={<FormattedMessage id="NewTicket.ChooseCategory3" />}
                                                value={this.state.category3}
                                                options={this.state.cat3Options.filter(o => o.lvl === this.state.category2.value)}
                                                onChange={this.handleChangeSelect.bind(this, 'category3')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label><FormattedMessage id="NewTicket.Title" />*</Label>
                                        <FormattedMessage id="NewTicket.InsertTitle">
                                            { placeholder =>
                                                <Input
                                                    type="text"
                                                    name="title"
                                                    maxLength="300"
                                                    placeholder={placeholder}
                                                    onChange={this.handleChange}
                                                    value={this.state.title}
                                                    required={true}
                                                />
                                            }
                                        </FormattedMessage>
                                    </FormGroup>
                                </div>
                                <FormGroup className="mb-0" style={{ position: 'inherit', zIndex: '10' }}>
                                    <Label>{<FormattedMessage id="NewTicket.Message" />}</Label>
                                    <Card>
                                        <EditorText
                                            editorStyle={{ minHeight: '22vh', maxHeight: '22vh' }}
                                            editorState={this.state.editor && this.state.editor.editorState}
                                            onEditorStateChange={(newEditorState) => { this.onEditorStateChange(newEditorState, this.state.editor) }}
                                        />
                                    </Card>
                                </FormGroup>
                            </Col>

                            {/*this.state.category1 && this.state.category1.value === 23 ?
                                <Col className="col-6">
                                    <div className="small">
                                        <Row>
                                            <Col className="col-2">
                                                {<FormattedMessage id="NewTicket.Search" />}
                                            </Col>
                                            <Col className="text-center">
                                                <FormattedMessage id="NewTicket.Search">
                                                    {
                                                        placeholder => <Input type="text" placeholder={placeholder} onChange={(evt) => this.setState({ searchquery: evt.target.value })} value={this.state.searchquery ? this.state.searchquery : ''} />
                                                    }
                                                </FormattedMessage>
                                            </Col>
                                            <Col className="col-2">
                                                <Button className="btn-sm btn-host" onClick={this.getConfluenceInfo.bind(this)}><i className="fas fa-search" /></Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-1"><b>{<FormattedMessage id="NewTicket.Filter" />} </b></Col>
                                            <Col className="col-11 ">
                                                <Badge style={{ cursor: 'pointer' }} className={this.state.filterTroubleshooting ? 'btn-sm mr-2 py-1 btn-host ' : ' btn-sm mr-2 py-1'} onClick={() => this.setState(prevState => ({ filterTroubleshooting: !prevState.filterTroubleshooting }))} >
                                                    {<FormattedMessage id="NewTicket.Troubleshooting" />}
                                                </Badge>
                                                <Badge style={{ cursor: 'pointer' }} className={this.state.filterHowTo ? 'btn-sm mr-2 py-1 btn-host ' : ' btn-sm mr-2 py-1'} onClick={() => this.setState(prevState => ({ filterHowTo: !prevState.filterHowTo }))}>
                                                    {<FormattedMessage id="NewTicket.HowTo" />}
                                                </Badge>
                                            </Col>
                                        </Row>
                                        <BlankCard block={this.state.blockSearch}>
                                            {this.state.search && this.state.search.results && this.state.search.results.map((el, k) =>

                                                <CardBody className="py-1">
                                                    <Row className="mb-1">
                                                        <Col><i className="far fa-file-alt mr-2 "></i><b><a href={this.state.search._links.base + el.url} target="_blank"> {ReactHtmlParser(el.title.replace(/\@\@\@hl\@\@\@/gi, "<u>").replace(/\@\@\@endhl\@\@\@/gi, "</u>"))}</a></b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{ReactHtmlParser(el.excerpt.replace(/\@\@\@hl\@\@\@/gi, "<b>").replace(/\@\@\@endhl\@\@\@/gi, "</b>"))}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-secondary">{el.friendlyLastModified}</Col>
                                                    </Row>
                                                </CardBody>
                                            )}
                                        </BlankCard>
                                    </div>
                                </Col>
                            : ''*/}
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" type="submit"> {<FormattedMessage id="NewTicket.Save" />}</Button>
                        <Button color="secondary" onClick={this.props.toggleModal}>{<FormattedMessage id="NewTicket.Cancel" />}</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            )
    }
}
export default injectIntl(NewTicket);