import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button, Form, Badge, PopoverBody, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { handleNotification } from "../Base/Common/Notification";
import { StyledCard } from '../Base/Common/CommonUIComponents';
import EditorText from '../Base/Common/EditorText';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import "../../css/react_dates_overrides.css";
import StaticFields from './StaticFields';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { getAPI, postAPI, postMultiPartAPI } from "../Base/API";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import host from '../../img/host.png';
import DragAndDrop from './DragAndDrop'


export class TicketDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            ticketData: null,
            ticketDetails: null,
            editor: {},
            newMessage: '',
            files: [],
            selectedMessage: null,
            ticketClosed: false
        }
    }
    
    componentDidMount() {
        var editor = this.state.editor;
        const blocksFromHtml = htmlToDraft(this.state.newMessage);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        editor.editorState = EditorState.createWithContent(contentState);

        this.setState({
            editor: editor
        })
                       
        this.getTicketData();
    }

    getTicketData = () => {
        this.setState({ block: true });
        var params = "?Dummy=0&Nr=-1&Id=" + this.props.match.params.id + "&Type=-1&Status=-1&Priority=-1&ProfileUid=" +
            "&CategoryLvl1=-1&CategoryLvl2=-1&CategoryLvl3=-1&Start=" + this.state.start + "&Limit=" + this.state.limit;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.data && data.data.length > 0) {
                    this.setState({
                        ticketData: data.data[0],
                        block: false
                    });

                    this.getTicketDetails();

                    if (data.data[0].Status && data.data[0].Status === 5) {
                        this.setState({ ticketClosed: true })
                    }
                    else {
                        this.setState({ ticketClosed: false })
                    }
                }
            }
        }, "/api/HelpDesk/ticket" + params);
    }

    getTicketDetails = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({
                    ticketDetails: data.data,
                    block: false
                });
            }
        }, "/api/HelpDesk/tickets/item?ticketId=" + this.props.match.params.id + "&itemId=0");
    }

    saveMessage = (event) => {
        event.preventDefault();
        
        if (this.state.editor.editorState && this.state.editor.editorState.getCurrentContent().hasText()) {
            this.setState({ block: true });

            if (this.state.selectedMessage) {
                var body = { ...this.state.selectedMessage };
                body.Text = draftToHtml(convertToRaw(this.state.editor.editorState.getCurrentContent()));
            }
            else {
                var body = {
                    ID: 0,
                    Text: draftToHtml(convertToRaw(this.state.editor.editorState.getCurrentContent())),
                    TicketId: this.props.match.params.id
                };
            }

            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.success === false) {
                        handleNotification(data, <FormattedMessage id="TicketDetail.ErrorSendingMessage" />, <FormattedMessage id="Generic.Error" />);
                        this.setState({ block: false })
                    }
                    else {
                        if (this.state.files && this.state.files.length > 0) {
                            this.sendAttach(data.newid);
                        } else {
                            this.getTicketDetails();
                        }

                        handleNotification(data, <FormattedMessage id="TicketDetail.MessageSent" />, <FormattedMessage id="Generic.Success" />);
                        this.setState({ editor: EditorState.createEmpty(), files: [], selectedMessage: null });
                    }
                    this.setState({ newMessage: '' });
                }
            }, "/api/HelpDesk/tickets/item", body);
        }
        else {
            handleNotification('', <FormattedMessage id="TicketDetail.FillOutTheMessageField" />, <FormattedMessage id="TicketDetail.RequiredField" />, 'info');
        }
    }
       
    sendAttach = (messageId) => {
        const { files } = this.state;

        const formData = new FormData();
        formData.append('ticketId', this.props.match.params.id);
        formData.append('itemId', messageId);
        files.forEach(file => {
            formData.append('file', file);
        })
        
        postMultiPartAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.success === false) {
                    handleNotification(data, data.info, <FormattedMessage id="Generic.Error" />);
                }
                this.setState({ file: null }, () => this.getTicketDetails());
            }
        }, '/API/v1/ExternalInterfaces/SupportManagement_Tickets_Upload', formData);
    }

    onEditorStateChange = (newEditorState, item) => {
        item.editorState = newEditorState;

        this.setState({
            editor: item
        })
    }

    addAttachement = () => {
        document.getElementById("selectImage").click();
    }

    addFile = () => {
        var file = document.querySelector('input[type=file]').files[0];

        if (this.state.files.reduce((acc, current) => acc + current.size, 0) + file.size <= 5242880) { //5MB
            this.readFile(file);
        }
        else {
            handleNotification('', <FormattedMessage id="TicketDetail.PleaseSelectAFileLessThan" />, <FormattedMessage id="TicketDetail.SelectedFileIsTooBig" />);
        }
    }

    readFile = (file) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            var files = [...this.state.files];

            files.push(file);

            this.setState({ files })

        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleDrop = (files) => {
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword',
                'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/x-zip-compressed',
                'text/plain', 'application/pdf', 'application/vnd.rar', 'application/rtf', 'application/x-7z-compressed', 'application/zip'];

            if (this.state.files.reduce((acc, current) => acc + current.size, 0) + file.size <= 5242880) { //5MB
                if (validTypes.indexOf(file.type) !== -1 || file.type.includes("image")) {
                    this.readFile(file);
                }
                else {
                    handleNotification('', <FormattedMessage id="TicketDetail.PleaseSelectAValidFile" />, <FormattedMessage id="TicketDetail.SelectedFileIsNotValid" />);
                }
            }
            else {
                handleNotification('', <FormattedMessage id="TicketDetail.PleaseSelectAFileLessThan" />, <FormattedMessage id="TicketDetail.SelectedFileIsTooBig" />);
            }        
        }
    }

    removeFile = (file) => {
        var { files } = this.state;

        files = files.filter(f => f !== file);

        this.setState({ files })
    }

    editMessage = (message) => {

        if (message && message.Text) {
            this.setState({ block: true })

            var editor = this.state.editor;
            const blocksFromHtml = htmlToDraft(message.Text);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            editor.editorState = EditorState.createWithContent(contentState);

            this.setState({
                selectedMessage: message,
                editor: editor,
                block: false
            })
        }
    }

    cancelEdit = () => {
        this.setState({
            selectedMessage: null,
            editor: EditorState.createEmpty()
        })
    }
    
    render() {
        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <div>
                    <StyledCard icon="fas fa-ticket-alt" title={<FormattedMessage id="ticketDetails.TicketDetails" />} help="" error={this.state.error}>
                        <div style={{
                            position: 'relative',
                            height: '100%'
                        }}>

                            {this.state.ticketData ?
                                <StaticFields
                                    ticketData={this.state.ticketData}
                                    ticketId={this.props.match.params.id}
                                    getTicketData={this.getTicketData}
                                    ticketClosed={this.state.ticketData.Status && this.state.ticketData.Status === 5 ? true : false}
                                />
                            : ''}
                       
                            {this.state.ticketClosed === false ?
                                <Card className="p-2 shadow newMessageCard">
                                    <Form onSubmit={this.saveMessage}>
                                        <Row>
                                            <Col>
                                                <div style={{ border: "1px solid #d5d5d5", borderRadius: "4px" }}>
                                                    <EditorText
                                                        required
                                                        editorState={this.state.editor && this.state.editor.editorState}
                                                        onEditorStateChange={(newEditorState) => { this.onEditorStateChange(newEditorState, this.state.editor) }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-2 pr-0">
                                                <DragAndDrop
                                                    handleDrop={this.handleDrop}
                                                />
                                            </Col>
                                            <Col className="col-1 text-right">
                                                <Row>
                                                    <Col>
                                                        <Button className="btn-sm btn-host mr-2" id="msgSubmitImgbutton" onClick={this.addAttachement}>
                                                            <i className="fas fa-paperclip"></i>
                                                        </Button>
                                                        <UncontrolledTooltip placement="bottom" target="msgSubmitImgbutton">
                                                            <FormattedMessage id="TicketDetail.MaxFileSize" />
                                                        </UncontrolledTooltip>

                                                        <Button type="submit" className="btn-sm btn-host">
                                                            <i className="fas fa-play"></i>
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-2">
                                                    <Col>
                                                        {this.state.selectedMessage ?
                                                            <Button className="btn-sm btn-host ml-2" onClick={this.cancelEdit}>
                                                                <i className="fas fa-times"></i>
                                                            </Button>
                                                            : ''}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <input
                                            key="msgSubmitPhotobutton"
                                            hidden
                                            type="file"
                                            id='selectImage'
                                            onChange={this.addFile.bind(this)}
                                            accept="image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword,
                                                    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                                                    text/plain, application/pdf, application/vnd.rar, application/rtf, application/x-7z-compressed, application/zip, application/x-zip-compressed"
                                        >
                                        </input>

                                        {this.state.files.length > 0 && this.state.files.map((file, key) =>
                                            <Row className="mt-2 pl-3" key={key}>
                                                <Col className="pr-5 pb-2 col-10">
                                                    <i className="fas fa-paperclip pr-1"></i> {file.name} ({Math.round(file.size / 1024)} KB)
                                                </Col>

                                                <Col className="col-1 text-right pr-4">
                                                    <i className="fas fa-trash-alt text-right" onClick={() => this.removeFile(file)} style={{ cursor: 'pointer', color: '#21bd3a' }} />
                                                </Col>
                                            </Row>
                                        )}
                                    </Form>
                                </Card>
                            : ''}
                        
                            <Card className="border-0" >
                                <CardBody className="px-1">
                                    {this.state.ticketDetails && this.state.ticketDetails.length > 0 ? this.state.ticketDetails.map((msg, index) =>
                                        <div key={index}>
                                            {index === 0 || !moment(msg.UpdatedOn).isSame(this.state.ticketDetails[index - 1].UpdatedOn, 'day') ?
                                                <Row>
                                                    <Col className=" col-12 text-center  ">
                                                        <Badge className="btn-host my-3 p-1" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                                            {moment(msg.UpdatedOn).format("ddd, DD/MM")}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            : ''}

                                            <Row key={index} style={index + 1 === this.state.ticketDetails.length ? { paddingBottom: '100px' } : {}}>
                                                <Col className="col-12" className="mb-2">
                                                    <div color="light" className={msg.Type === 1 ? "float-left pt-2 pb-2 text-secondary messageBox" : " float-right px-1 text-secondary messageBox"} style={msg.Type === 1 ? { overflowX: 'auto', backgroundColor: '#f7f7f7' } : { overflowX: 'auto', backgroundColor: 'rgba(213, 229, 158, 0.15)' }}>
                                                        {ReactHtmlParser(msg.Text)}
                                                        <p className={'msg-info ' + (msg.Type === 1 ? "float-left" : "floar-right")}>
                                                            {msg.Type === 1 ? <img src={host} height="15px" alt="" /> : <i className="fas fa-hotel" />} # {msg.Nr}  {msg.ExternalUser ? msg.ExternalUser : ' - hotel'} - às {moment(msg.UpdatedOn).format("HH:mm")}

                                                            {msg.HasAttachment ? <>
                                                                <i className="fas fa-paperclip mr-2 ml-3" style={{ cursor: 'pointer', fontSize: '13px' }} id={`attachment${index}`} />
                                                                <UncontrolledPopover placement="right" target={`attachment${index}`}>
                                                                    <PopoverBody>
                                                                        {msg.Attachments && msg.Attachments.map((attach, key) =>
                                                                            <p key={key} className="mb-1"><a href={attach.Path} target="_blank">- {attach.Name}</a></p>
                                                                            )}
                                                                    </PopoverBody>
                                                                </UncontrolledPopover></>
                                                                : ''}

                                                            {msg.Type === 2 && this.state.ticketData.Type && this.state.ticketData.Type === 1 && this.state.ticketClosed === false ?
                                                                <i className="fas fa-pencil-alt mr-2 ml-1" style={{ cursor: 'pointer', fontSize: '13px' }} onClick={() => this.editMessage(msg)} />
                                                            : ''}
                                                        </p>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        ) :
                                        <div style={{ textAlign: 'center' }}>
                                            {<FormattedMessage id="TicketDetails.ThereAreNoMessages" />}
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </StyledCard>
                </div>
            </BlockUi>
        );
    }
}